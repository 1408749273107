import { useState, useEffect, useCallback, useMemo, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchPlayerNews, fetchPlayer } from "../store/players-actions.js";
import { playersActions } from "../store/players-slice.js";
import { shortDateString } from "../utils.js";
import useMultiResourceLoader from "../hooks/use-multi-resource-loader.js";
import classes from "./PlayerNews.module.css";

function PlayerNews({
    playerId,
    teamId,
    limit,
    onPlayerClick,
    isLoadingHandler,
}) {
    const dispatch = useDispatch();
    const isTeamMode = !playerId && teamId;
    const defaultLimit = isTeamMode ? 20 : 3;
    const actualLimit = limit || defaultLimit;

    // Use the multi-resource loader to manage loading states
    const { addLoader } = useMultiResourceLoader(isLoadingHandler);

    const playerNews = useSelector((state) =>
        playerId
            ? state.players.playernews[playerId] || []
            : teamId
            ? Object.values(state.players.playernews)
                  .flat()
                  .filter((news) => news.team_id === teamId)
            : []
    );

    const sortedNews = useMemo(() => {
        return [...playerNews].sort(
            (a, b) => new Date(b.datetime) - new Date(a.datetime)
        );
    }, [playerNews]);

    const players = useSelector((state) => state.players.players);

    // Use a ref to track which player/team we've already fetched news for
    const lastFetchedIdRef = useRef({
        playerId: null,
        teamId: null,
    });

    // Create our fetch news function with useCallback to stabilize it
    const fetchNewsData = useCallback(() => {
        // Only fetch if we have valid params
        if (!playerId && !teamId) return;

        // Check if we've already fetched for this specific player/team
        const alreadyFetched =
            (playerId && playerId === lastFetchedIdRef.current.playerId) ||
            (teamId && teamId === lastFetchedIdRef.current.teamId);

        // Skip if we've already fetched for this ID
        if (alreadyFetched) return;

        // Create a unique loader ID for the news fetch
        const newsLoaderId = `news-${playerId || "team-" + teamId}`;

        let action = null;
        if (playerId) {
            action = fetchPlayerNews(playerId, teamId, actualLimit);
        } else if (teamId) {
            action = fetchPlayerNews(null, teamId, actualLimit);
        }

        if (action) {
            // Update the last fetched IDs
            lastFetchedIdRef.current = {
                playerId,
                teamId,
            };

            addLoader(newsLoaderId, action);
        }
    }, [playerId, teamId, actualLimit, addLoader]);

    // Call the fetch news function in useEffect
    useEffect(() => {
        fetchNewsData();
    }, [fetchNewsData]);

    // Keep track of players we've already triggered fetches for
    const fetchedPlayerIdsRef = useRef(new Set());
    const lastTeamIdRef = useRef(null);

    // Reset tracked player IDs when team changes
    useEffect(() => {
        if (teamId !== lastTeamIdRef.current) {
            fetchedPlayerIdsRef.current = new Set();
            lastTeamIdRef.current = teamId;
        }
    }, [teamId]);

    // Create our fetch missing players function with useCallback
    const fetchMissingPlayers = useCallback(() => {
        if (isTeamMode && playerNews.length > 0) {
            // Use a Set to deduplicate player IDs that need fetching
            const playerIdsToFetch = new Set();

            // Collect unique player IDs to fetch
            playerNews.forEach((news) => {
                if (
                    news.player_id &&
                    !players[news.player_id] &&
                    !fetchedPlayerIdsRef.current.has(news.player_id)
                ) {
                    playerIdsToFetch.add(news.player_id);
                    // Mark this ID as "fetched" to prevent duplicate requests
                    fetchedPlayerIdsRef.current.add(news.player_id);
                }
            });

            // Only proceed if we have players to fetch
            if (playerIdsToFetch.size > 0) {
                // Fetch each unique player
                playerIdsToFetch.forEach((id) => {
                    const loaderId = `player-${id}`;
                    addLoader(loaderId, fetchPlayer(id));
                });
            }
        }
    }, [isTeamMode, playerNews, players, addLoader]);

    // Call the fetch missing players function in useEffect
    useEffect(() => {
        fetchMissingPlayers();
    }, [fetchMissingPlayers]);

    // Using a map to manage multiple WebSocket connections
    const [webSockets, setWebSockets] = useState(new Map());

    // Function to setup event listeners for a WebSocket
    const setupWebSocketEvents = useCallback(
        (websocket, entity, updateFunc) => {
            websocket.onopen = () => {
                console.log(`${entity} Updates WebSocket connected.`);
            };

            websocket.onmessage = (event) => {
                const data = JSON.parse(event.data)?.data;
                console.log(
                    `${entity} WebSocket update received for update ${data.id}.`
                );

                dispatch(updateFunc([data]));
            };

            websocket.onclose = () => {
                console.log(`${entity} WebSocket disconnected.`);
            };
        },
        [dispatch]
    );

    const [shouldUpdateWebSockets, setShouldUpdateWebSockets] = useState(false);

    // Determine when to update the WebSockets
    useEffect(() => {
        if (playerId || teamId) {
            setShouldUpdateWebSockets(true);
        }
    }, [playerId, teamId]);

    // Effect for setting up WebSocket connections
    useEffect(() => {
        if (shouldUpdateWebSockets) {
            // Close existing connections
            webSockets.forEach((ws) => ws.close());
            const newWebSockets = new Map();

            // Create new connections
            let websocketUrl;
            if (playerId) {
                websocketUrl = `playernews/${playerId}/`;
            } else if (teamId) {
                websocketUrl = `teamnews/${teamId}/`;
            }

            if (websocketUrl) {
                const newsWebsocket = new WebSocket(
                    process.env.REACT_APP_HABSHUB_WS_BASE_URL + websocketUrl
                );

                // Setup event listeners for each WebSocket
                setupWebSocketEvents(
                    newsWebsocket,
                    playerId ? "Player News" : "Team News",
                    playersActions.updatePlayerNews
                );

                newWebSockets.set(1, newsWebsocket);
            }

            setWebSockets(newWebSockets);
            setShouldUpdateWebSockets(false); // Reset the trigger
        }

        // Cleanup function to close all connections
        return () => {
            webSockets.forEach((ws) => ws.close());
        };
    }, [
        shouldUpdateWebSockets,
        setupWebSocketEvents,
        webSockets,
        playerId,
        teamId,
    ]);

    if (!playerNews.length) {
        return null;
    }

    // Show only up to specified limit of latest news
    const latestNews = sortedNews.slice(
        0,
        Math.min(actualLimit, sortedNews.length)
    );

    // Render team mode with grouped news by date
    if (isTeamMode) {
        // Get all the dates that we have in our limited set
        const datesToShow = new Set();
        latestNews.forEach((news) => {
            datesToShow.add(shortDateString(news.datetime));
        });

        return (
            <>
                {Array.from(datesToShow).map((date, dateIndex) => (
                    <div
                        key={date}
                        className={
                            classes.date_group +
                            (dateIndex === 0 ? " " + classes.first : "")
                        }
                    >
                        <div className={classes.date_header}>{date}</div>
                        {latestNews
                            .filter(
                                (news) =>
                                    shortDateString(news.datetime) === date
                            )
                            .map((news, newsIndex) => (
                                <div
                                    key={newsIndex}
                                    className={
                                        classes.news_container_grouped +
                                        (news.player_id && onPlayerClick
                                            ? " " + classes.clickable
                                            : "")
                                    }
                                    onClick={() => {
                                        if (news.player_id && onPlayerClick) {
                                            onPlayerClick(
                                                news.player_id,
                                                news.competition_id || 1
                                            );
                                        }
                                    }}
                                >
                                    <div className={classes.news_summary}>
                                        {news.player_id && (
                                            <>
                                                <span
                                                    className={
                                                        classes.news_player
                                                    }
                                                >
                                                    {players[news.player_id]
                                                        ?.known_name ||
                                                        "Unknown Player"}
                                                </span>{" "}
                                            </>
                                        )}
                                        {news.summary}
                                    </div>
                                </div>
                            ))}
                    </div>
                ))}
            </>
        );
    }

    // Player mode (unchanged)
    return (
        <>
            {latestNews.map((news, index) => (
                <div
                    key={index}
                    className={
                        classes.news_container +
                        (index === 0 ? " " + classes.first : "")
                    }
                >
                    <div className={classes.news_date}>
                        {shortDateString(news.datetime)}
                    </div>

                    <div className={classes.news_summary}>{news.summary}</div>
                </div>
            ))}
        </>
    );
}

export default PlayerNews;
