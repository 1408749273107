import { useState, useEffect, useCallback, useRef } from "react";

const useElementDimensions = (widthRanges = []) => {
    const elementRef = useRef(null);
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
    const [widthClasses, setWidthClasses] = useState([]);

    // Memoize the width classes calculation
    const getWidthClasses = useCallback(
        (width) => {
            return widthRanges.map((range) => ({
                min: range.min,
                max: range.max,
                matches:
                    width >= (range.min || 0) &&
                    width <= (range.max || Infinity),
            }));
        },
        [widthRanges]
    );

    useEffect(() => {
        if (!elementRef.current) return;

        // Store previous dimensions to prevent unnecessary updates
        let prevWidth = 0;
        let prevHeight = 0;

        const resizeObserver = new ResizeObserver((entries) => {
            for (const entry of entries) {
                const { width, height } = entry.contentRect;

                // Only update if dimensions actually changed
                if (width !== prevWidth || height !== prevHeight) {
                    prevWidth = width;
                    prevHeight = height;

                    // Use functional updates to ensure we don't trigger
                    // unnecessary re-renders when values haven't changed
                    setDimensions((prev) => {
                        if (prev.width === width && prev.height === height) {
                            return prev;
                        }
                        return { width, height };
                    });

                    setWidthClasses((prev) => {
                        const newClasses = getWidthClasses(width);
                        // Deep compare previous and new classes
                        if (
                            JSON.stringify(prev) === JSON.stringify(newClasses)
                        ) {
                            return prev;
                        }
                        return newClasses;
                    });
                }
            }
        });

        resizeObserver.observe(elementRef.current);

        return () => {
            resizeObserver.disconnect();
        };
    }, [getWidthClasses]);

    return { elementRef, dimensions, widthClasses };
};

export default useElementDimensions;
