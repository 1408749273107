import { uiActions } from "./ui-slice";
import { fetchData } from "../utils";
import { playersActions } from "./players-slice";
import { teamsActions } from "./teams-slice";

let ongoingRequests = {
    fetchPlayerAgencies: null, // This will be either null or a Promise
    fetchPlayerAgents: null, // This will be either null or a Promise
    fetchPlayer: {},
    fetchNHLTeamReserveListPlayers: {},
    fetchPlayerDatastash: {},
    fetchPlayersDatastash: {},
    fetchPlayerSeasonDatastash: {},
    fetchPlayerSeasonsDatastash: {},
    fetchPlayersSeasonsDatastash: {},
    fetchPlayerRinknetReports: {},
    fetchPlayerInjuries: {},
    fetchPlayerStaffNotes: {},
    fetchPlayerStatusUpdates: {},
    fetchPlayerDevelopmentPriorities: {},
    fetchPlayerInteractions: {},
    fetchSeasonPlayerInteractions: {},
    fetchPlayerDevelopmentSessions: {},
    fetchSeasonPlayerDevelopmentSessions: {},
    fetchTeamLatestPlayerStatusUpdates: {},
    fetchTeamPlayerInjuries: {},
    fetchTeamDevelopmentPriorities: {},
    fetchPlayersContracts: {},
    fetchPlayerNews: {},
};

export const fetchPlayerAgencies = () => {
    return async (dispatch) => {
        if (!ongoingRequests.fetchPlayerAgencies) {
            ongoingRequests.fetchPlayerAgencies = fetchData(
                process.env.REACT_APP_HABSHUB_API_BASE_URL +
                    "hockey_analytics/playeragencies/",
                "Could not fetch player agencies data!"
            )
                .then((playerAgenciesData) => {
                    dispatch(
                        playersActions.updatePlayerAgencies(playerAgenciesData)
                    );
                    ongoingRequests.fetchPlayerAgencies = null;
                    return playerAgenciesData;
                })
                .catch((error) => {
                    dispatch(
                        uiActions.showNotification({
                            status: "error",
                            title: "Error!",
                            message:
                                "Fetching all player agencies data failed!",
                        })
                    );
                    ongoingRequests.fetchPlayerAgencies = null;
                    throw error;
                });
        }

        return ongoingRequests.fetchPlayerAgencies;
    };
};

export const fetchPlayerAgents = () => {
    return async (dispatch) => {
        if (!ongoingRequests.fetchPlayerAgents) {
            ongoingRequests.fetchPlayerAgents = fetchData(
                process.env.REACT_APP_HABSHUB_API_BASE_URL +
                    "hockey_analytics/playeragents/",
                "Could not fetch player agents data!"
            )
                .then((playerAgentsData) => {
                    dispatch(
                        playersActions.updatePlayerAgents(playerAgentsData)
                    );
                    ongoingRequests.fetchPlayerAgents = null;
                    return playerAgentsData;
                })
                .catch((error) => {
                    dispatch(
                        uiActions.showNotification({
                            status: "error",
                            title: "Error!",
                            message: "Fetching all player agents data failed!",
                        })
                    );
                    ongoingRequests.fetchPlayerAgents = null;
                    throw error;
                });
        }

        return ongoingRequests.fetchPlayerAgents;
    };
};

export const fetchPlayer = (playerId) => {
    return async (dispatch) => {
        if (!ongoingRequests.fetchPlayer[playerId]) {
            ongoingRequests.fetchPlayer[playerId] = fetchData(
                process.env.REACT_APP_HABSHUB_API_BASE_URL +
                    "hockey_analytics/players/" +
                    playerId +
                    "/",
                "Could not fetch player data!"
            )
                .then((playerData) => {
                    dispatch(playersActions.updatePlayer(playerData));
                    delete ongoingRequests.fetchPlayer[playerId];
                    return playerData;
                })
                .catch((error) => {
                    dispatch(
                        uiActions.showNotification({
                            status: "error",
                            title: "Error!",
                            message: "Fetching player data failed!",
                        })
                    );
                    delete ongoingRequests.fetchPlayer[playerId];
                    throw error;
                });
        }

        return ongoingRequests.fetchPlayer[playerId];
    };
};

// Fetches all players on a given NHL team's reserve list from the API
export const fetchNHLTeamReserveListPlayers = (
    nhlRightsOwnerTeamId,
    includeAhl = false
) => {
    return async (dispatch) => {
        const key = `${nhlRightsOwnerTeamId}-${includeAhl}`; // Create a unique key for each nhlRightsOwnerTeamId and includeAhl flag combination
        const filterName = !includeAhl
            ? "nhl_rights_owner_team_id"
            : "nhl_or_affiliate_rights_owner_nhl_team_id";

        if (!ongoingRequests.fetchNHLTeamReserveListPlayers[key]) {
            ongoingRequests.fetchNHLTeamReserveListPlayers[key] = fetchData(
                process.env.REACT_APP_HABSHUB_API_BASE_URL +
                    "hockey_analytics/players/?" +
                    filterName +
                    "=" +
                    nhlRightsOwnerTeamId,
                "Could not fetch players data!"
            )
                .then((playersData) => {
                    dispatch(playersActions.updatePlayers(playersData));
                    dispatch(
                        teamsActions.updateTeamReserveListPlayerIds({
                            teamId: nhlRightsOwnerTeamId,
                            data: playersData,
                        })
                    );
                    ongoingRequests.fetchNHLTeamReserveListPlayers[key] = null;
                    return playersData;
                })
                .catch((error) => {
                    dispatch(
                        uiActions.showNotification({
                            status: "error",
                            title: "Error!",
                            message:
                                "Fetching all players on a given NHL team's reserve list failed!",
                        })
                    );
                    ongoingRequests.fetchNHLTeamReserveListPlayers[key] = null;
                    throw error;
                });
        }

        return ongoingRequests.fetchNHLTeamReserveListPlayers[key];
    };
};

export const fetchPlayerDatastash = (playerId, datastashName) => {
    return async (dispatch) => {
        const key = `${playerId}-${datastashName}`; // Create a unique key for each playerId and datastashName combination

        if (!ongoingRequests.fetchPlayerDatastash[key]) {
            ongoingRequests.fetchPlayerDatastash[key] = fetchData(
                process.env.REACT_APP_HABSHUB_API_BASE_URL +
                    "hockey_analytics/datastashes/players/" +
                    datastashName +
                    "/?player_ids=" +
                    playerId,
                "Could not fetch player data!"
            )
                .then((data) => {
                    const dataPlayload = data.length > 0 ? data[0].data : [];
                    dispatch(
                        playersActions.updatePlayerDatastash({
                            playerId: playerId,
                            datastashName: datastashName,
                            data: dataPlayload,
                        })
                    );
                    delete ongoingRequests.fetchPlayerDatastash[key];
                    return dataPlayload;
                })
                .catch((error) => {
                    dispatch(
                        uiActions.showNotification({
                            status: "error",
                            title: "Error!",
                            message: `Fetching player data stash ${datastashName} for player ${playerId} failed!`,
                        })
                    );
                    delete ongoingRequests.fetchPlayerDatastash[key];
                    throw error;
                });
        }

        return ongoingRequests.fetchPlayerDatastash[key];
    };
};

export const fetchPlayersDatastash = (playerIds, datastashName) => {
    return async (dispatch) => {
        const key = `${playerIds.join(",")}-${datastashName}`; // Create a unique key for each playerId and datastashName combination

        if (!ongoingRequests.fetchPlayersDatastash[key]) {
            ongoingRequests.fetchPlayersDatastash[key] = fetchData(
                process.env.REACT_APP_HABSHUB_API_BASE_URL +
                    "hockey_analytics/datastashes/players/" +
                    datastashName +
                    "/?" +
                    playerIds.map((id) => `player_ids=${id}`).join("&"),
                "Could not fetch players data!"
            )
                .then((data) => {
                    dispatch(
                        playersActions.updatePlayersDatastash({
                            datastashName: datastashName,
                            data: data, // As we are receiving array of objects for all player ids
                        })
                    );
                    delete ongoingRequests.fetchPlayersDatastash[key];
                    return data;
                })
                .catch((error) => {
                    dispatch(
                        uiActions.showNotification({
                            status: "error",
                            title: "Error!",
                            message: `Fetching player data stash ${datastashName} for players ${playerIds.join(
                                ","
                            )} failed!`,
                        })
                    );
                    delete ongoingRequests.fetchPlayersDatastash[key];
                    throw error;
                });
        }

        return ongoingRequests.fetchPlayersDatastash[key];
    };
};

export const fetchPlayerSeasonDatastash = (
    playerId,
    seasonId,
    datastashName
) => {
    return async (dispatch) => {
        // Create a unique key for each playerId, seasonId and datastashName combination
        const key = `${playerId}-${seasonId}-${datastashName}`;

        if (!ongoingRequests.fetchPlayerSeasonDatastash[key]) {
            ongoingRequests.fetchPlayerSeasonDatastash[key] = fetchData(
                process.env.REACT_APP_HABSHUB_API_BASE_URL +
                    "hockey_analytics/seasondatastashes/players/" +
                    datastashName +
                    "/?player_ids=" +
                    playerId +
                    "&season_ids=" +
                    seasonId,
                "Could not fetch player data!"
            )
                .then((data) => {
                    const dataPlayload = data.length > 0 ? data[0].data : [];
                    dispatch(
                        playersActions.updatePlayerSeasonDatastash({
                            playerId: playerId,
                            datastashName: datastashName,
                            seasonId: seasonId,
                            data: dataPlayload,
                        })
                    );
                    delete ongoingRequests.fetchPlayerSeasonDatastash[key];
                    return dataPlayload;
                })
                .catch((error) => {
                    dispatch(
                        uiActions.showNotification({
                            status: "error",
                            title: "Error!",
                            message: `Fetching player data stash ${datastashName} for player ${playerId}, season ${seasonId} failed!`,
                        })
                    );
                    delete ongoingRequests.fetchPlayerSeasonDatastash[key];
                    throw error;
                });
        }

        return ongoingRequests.fetchPlayerSeasonDatastash[key];
    };
};

export const fetchPlayerSeasonsDatastash = (playerId, datastashName) => {
    return async (dispatch) => {
        const key = `${playerId}-${datastashName}`;

        if (!ongoingRequests.fetchPlayerSeasonsDatastash[key]) {
            ongoingRequests.fetchPlayerSeasonsDatastash[key] = fetchData(
                process.env.REACT_APP_HABSHUB_API_BASE_URL +
                    "hockey_analytics/seasondatastashes/players/" +
                    datastashName +
                    `/?player_ids=${playerId}`,
                "Could not fetch players data!"
            )
                .then((data) => {
                    dispatch(
                        playersActions.updatePlayerSeasonsDatastash({
                            playerId: playerId,
                            datastashName: datastashName,
                            data: data, // As we are receiving array of objects for a player and all season ids
                        })
                    );
                    delete ongoingRequests.fetchPlayerSeasonsDatastash[key];
                    return data;
                })
                .catch((error) => {
                    dispatch(
                        uiActions.showNotification({
                            status: "error",
                            title: "Error!",
                            message: `Fetching player data stash ${datastashName} for players ${playerId}, all seasons failed!`,
                        })
                    );
                    delete ongoingRequests.fetchPlayerSeasonsDatastash[key];
                    throw error;
                });
        }

        return ongoingRequests.fetchPlayerSeasonsDatastash[key];
    };
};

export const fetchPlayersSeasonsDatastash = (
    datastashName,
    playerIds = null,
    seasonIds = null
) => {
    return async (dispatch) => {
        // Create a unique key for each playerIds. seasonIds list and datastashName combination
        const key = `${(playerIds ? playerIds : "").join(",")}-${(seasonIds
            ? seasonIds
            : ""
        ).join(",")}-${datastashName}`;

        if (!ongoingRequests.fetchPlayersSeasonsDatastash[key]) {
            const params = [
                playerIds &&
                    playerIds.map((id) => `player_ids=${id}`).join("&"),
                seasonIds &&
                    seasonIds.map((id) => `season_ids=${id}`).join("&"),
            ]
                .filter(Boolean)
                .join("&");

            ongoingRequests.fetchPlayersSeasonsDatastash[key] = fetchData(
                process.env.REACT_APP_HABSHUB_API_BASE_URL +
                    "hockey_analytics/seasondatastashes/players/" +
                    datastashName +
                    "/" +
                    (params ? `?${params}` : ""),
                "Could not fetch players data!"
            )
                .then((data) => {
                    dispatch(
                        playersActions.updatePlayersSeasonsDatastash({
                            playerIds: playerIds,
                            seasonIds: seasonIds,
                            datastashName: datastashName,
                            data: data, // As we are receiving array of objects for all player and season ids
                        })
                    );
                    delete ongoingRequests.fetchPlayersSeasonsDatastash[key];
                    return data;
                })
                .catch((error) => {
                    dispatch(
                        uiActions.showNotification({
                            status: "error",
                            title: "Error!",
                            message: `Fetching player data stash ${datastashName} for ${
                                playerIds
                                    ? "players " + playerIds.join(",")
                                    : "all players"
                            }, ${
                                seasonIds
                                    ? "seasons " + seasonIds.join(",")
                                    : "all seasons"
                            } failed!`,
                        })
                    );
                    delete ongoingRequests.fetchPlayersSeasonsDatastash[key];
                    throw error;
                });
        }

        return ongoingRequests.fetchPlayersSeasonsDatastash[key];
    };
};

export const fetchPlayerRinknetReports = (playerId) => {
    return async (dispatch) => {
        if (!ongoingRequests.fetchPlayerRinknetReports[playerId]) {
            ongoingRequests.fetchPlayerRinknetReports[playerId] = fetchData(
                process.env.REACT_APP_HABSHUB_API_BASE_URL +
                    "hockey_analytics/players/" +
                    playerId +
                    "/rinknetgamereports/",
                "Could not fetch player rink net reports!"
            )
                .then((rinknetReportsData) => {
                    dispatch(
                        playersActions.updatePlayerRinknetReports(
                            rinknetReportsData
                        )
                    );
                    delete ongoingRequests.fetchPlayerRinknetReports[playerId];
                    return rinknetReportsData;
                })
                .catch((error) => {
                    dispatch(
                        uiActions.showNotification({
                            status: "error",
                            title: "Error!",
                            message:
                                "Fetching player rink net report data failed!",
                        })
                    );
                    delete ongoingRequests.fetchPlayerRinknetReports[playerId];
                    throw error;
                });
        }

        return ongoingRequests.fetchPlayerRinknetReports[playerId];
    };
};

export const fetchPlayerInjuries = (playerId) => {
    return async (dispatch) => {
        if (!ongoingRequests.fetchPlayerInjuries[playerId]) {
            ongoingRequests.fetchPlayerInjuries[playerId] = fetchData(
                process.env.REACT_APP_HABSHUB_API_BASE_URL +
                    "hockey_analytics/playerinjuries/?playerId=" +
                    playerId,
                "Could not fetch player injuries!"
            )
                .then((injuriesData) => {
                    dispatch(playersActions.updatePlayerInjuries(injuriesData));
                    delete ongoingRequests.fetchPlayerInjuries[playerId];
                    return injuriesData;
                })
                .catch((error) => {
                    dispatch(
                        uiActions.showNotification({
                            status: "error",
                            title: "Error!",
                            message: "Fetching player injuries data failed!",
                        })
                    );
                    delete ongoingRequests.fetchPlayerInjuries[playerId];
                    throw error;
                });
        }

        return ongoingRequests.fetchPlayerInjuries[playerId];
    };
};

export const fetchPlayerStaffNotes = (playerId) => {
    return async (dispatch) => {
        if (!ongoingRequests.fetchPlayerStaffNotes[playerId]) {
            ongoingRequests.fetchPlayerStaffNotes[playerId] = fetchData(
                process.env.REACT_APP_HABSHUB_API_BASE_URL +
                    "hockey_analytics/playerstaffnotes/?playerId=" +
                    playerId,
                "Could not fetch player staff notes!"
            )
                .then((staffNotesData) => {
                    dispatch(
                        playersActions.updatePlayerStaffNotes(staffNotesData)
                    );
                    delete ongoingRequests.fetchPlayerStaffNotes[playerId];
                    return staffNotesData;
                })
                .catch((error) => {
                    dispatch(
                        uiActions.showNotification({
                            status: "error",
                            title: "Error!",
                            message: "Fetching player staff notes data failed!",
                        })
                    );
                    delete ongoingRequests.fetchPlayerStaffNotes[playerId];
                    throw error;
                });
        }

        return ongoingRequests.fetchPlayerStaffNotes[playerId];
    };
};

export const fetchPlayerStatusUpdates = (playerId) => {
    return async (dispatch) => {
        if (!ongoingRequests.fetchPlayerStatusUpdates[playerId]) {
            ongoingRequests.fetchPlayerStatusUpdates[playerId] = fetchData(
                process.env.REACT_APP_HABSHUB_API_BASE_URL +
                    "hockey_analytics/playerstatusupdates/?playerId=" +
                    playerId,
                "Could not fetch player status updates!"
            )
                .then((statusUpdatesData) => {
                    dispatch(
                        playersActions.updatePlayerStatusUpdates(
                            statusUpdatesData
                        )
                    );
                    delete ongoingRequests.fetchPlayerStatusUpdates[playerId];
                    return statusUpdatesData;
                })
                .catch((error) => {
                    dispatch(
                        uiActions.showNotification({
                            status: "error",
                            title: "Error!",
                            message:
                                "Fetching player status updates data failed!",
                        })
                    );
                    delete ongoingRequests.fetchPlayerStatusUpdates[playerId];
                    throw error;
                });
        }

        return ongoingRequests.fetchPlayerStatusUpdates[playerId];
    };
};

export const fetchPlayerDevelopmentPriorities = (playerId) => {
    return async (dispatch) => {
        if (!ongoingRequests.fetchPlayerDevelopmentPriorities[playerId]) {
            ongoingRequests.fetchPlayerDevelopmentPriorities[playerId] =
                fetchData(
                    process.env.REACT_APP_HABSHUB_API_BASE_URL +
                        "hockey_analytics/playerdevelopmentpriorities/?playerId=" +
                        playerId,
                    "Could not fetch player development priorities!"
                )
                    .then((developmentPrioritiesData) => {
                        dispatch(
                            playersActions.updatePlayerDevelopmentPriorities(
                                developmentPrioritiesData
                            )
                        );
                        delete ongoingRequests.fetchPlayerDevelopmentPriorities[
                            playerId
                        ];
                        return developmentPrioritiesData;
                    })
                    .catch((error) => {
                        dispatch(
                            uiActions.showNotification({
                                status: "error",
                                title: "Error!",
                                message:
                                    "Fetching player development priorities data failed!",
                            })
                        );
                        delete ongoingRequests.fetchPlayerDevelopmentPriorities[
                            playerId
                        ];
                        throw error;
                    });
        }

        return ongoingRequests.fetchPlayerDevelopmentPriorities[playerId];
    };
};

export const fetchPlayerInteractions = (playerId) => {
    return async (dispatch) => {
        if (!ongoingRequests.fetchPlayerInteractions[playerId]) {
            ongoingRequests.fetchPlayerInteractions[playerId] = fetchData(
                process.env.REACT_APP_HABSHUB_API_BASE_URL +
                    "hockey_analytics/playerinteractions/?playerId=" +
                    playerId,
                "Could not fetch player interactions!"
            )
                .then((interactionsData) => {
                    dispatch(
                        playersActions.updatePlayerInteractions(
                            interactionsData
                        )
                    );
                    delete ongoingRequests.fetchPlayerInteractions[playerId];
                    return interactionsData;
                })
                .catch((error) => {
                    dispatch(
                        uiActions.showNotification({
                            status: "error",
                            title: "Error!",
                            message:
                                "Fetching player interactions data failed!",
                        })
                    );
                    delete ongoingRequests.fetchPlayerInteractions[playerId];
                    throw error;
                });
        }

        return ongoingRequests.fetchPlayerInteractions[playerId];
    };
};

export const fetchSeasonPlayerInteractions = (seasonId) => {
    return async (dispatch) => {
        if (!ongoingRequests.fetchSeasonPlayerInteractions[seasonId]) {
            ongoingRequests.fetchSeasonPlayerInteractions[seasonId] = fetchData(
                process.env.REACT_APP_HABSHUB_API_BASE_URL +
                    "hockey_analytics/playerinteractions/?seasonId=" +
                    seasonId,
                "Could not fetch player interactions!"
            )
                .then((interactionsData) => {
                    dispatch(
                        playersActions.updatePlayerInteractions(
                            interactionsData
                        )
                    );
                    delete ongoingRequests.fetchSeasonPlayerInteractions[
                        seasonId
                    ];
                    return interactionsData;
                })
                .catch((error) => {
                    dispatch(
                        uiActions.showNotification({
                            status: "error",
                            title: "Error!",
                            message:
                                "Fetching player interactions data failed!",
                        })
                    );
                    delete ongoingRequests.fetchSeasonPlayerInteractions[
                        seasonId
                    ];
                    throw error;
                });
        }

        return ongoingRequests.fetchSeasonPlayerInteractions[seasonId];
    };
};

export const fetchPlayerDevelopmentSessions = (playerId) => {
    return async (dispatch) => {
        if (!ongoingRequests.fetchPlayerDevelopmentSessions[playerId]) {
            ongoingRequests.fetchPlayerDevelopmentSessions[playerId] =
                fetchData(
                    process.env.REACT_APP_HABSHUB_API_BASE_URL +
                        "hockey_analytics/playerdevelopmentsessions/?playerId=" +
                        playerId,
                    "Could not fetch player development sessions!"
                )
                    .then((developmentSessionsData) => {
                        dispatch(
                            playersActions.updatePlayerDevelopmentSessions(
                                developmentSessionsData
                            )
                        );
                        delete ongoingRequests.fetchPlayerDevelopmentSessions[
                            playerId
                        ];
                        return developmentSessionsData;
                    })
                    .catch((error) => {
                        dispatch(
                            uiActions.showNotification({
                                status: "error",
                                title: "Error!",
                                message:
                                    "Fetching player development sessions data failed!",
                            })
                        );
                        delete ongoingRequests.fetchPlayerDevelopmentSessions[
                            playerId
                        ];
                        throw error;
                    });
        }

        return ongoingRequests.fetchPlayerDevelopmentSessions[playerId];
    };
};

export const fetchSeasonPlayerDevelopmentSessions = (seasonId) => {
    return async (dispatch) => {
        if (!ongoingRequests.fetchSeasonPlayerDevelopmentSessions[seasonId]) {
            ongoingRequests.fetchSeasonPlayerDevelopmentSessions[seasonId] =
                fetchData(
                    process.env.REACT_APP_HABSHUB_API_BASE_URL +
                        "hockey_analytics/playerdevelopmentsessions/?seasonId=" +
                        seasonId,
                    "Could not fetch player development sessions!"
                )
                    .then((developmentSessionsData) => {
                        dispatch(
                            playersActions.updatePlayerDevelopmentSessions(
                                developmentSessionsData
                            )
                        );
                        delete ongoingRequests
                            .fetchSeasonPlayerDevelopmentSessions[seasonId];
                        return developmentSessionsData;
                    })
                    .catch((error) => {
                        dispatch(
                            uiActions.showNotification({
                                status: "error",
                                title: "Error!",
                                message:
                                    "Fetching player development sessions data failed!",
                            })
                        );
                        delete ongoingRequests
                            .fetchSeasonPlayerDevelopmentSessions[seasonId];
                        throw error;
                    });
        }

        return ongoingRequests.fetchSeasonPlayerDevelopmentSessions[seasonId];
    };
};

export const patchPlayerScoutingRevisedProjection = (
    playerId,
    scoutingRevisedProjection
) => {
    return async (dispatch) => {
        const patchData = {
            scouting_revised_projection: scoutingRevisedProjection,
        };

        try {
            const response = await fetchData(
                `${process.env.REACT_APP_HABSHUB_API_BASE_URL}hockey_analytics/players/${playerId}/scoutingrevisedprojection/`,
                "Failed to patch player scouting revised projection!",
                "PATCH",
                patchData
            );

            // Updating the player development sessions in the Redux store.
            dispatch(playersActions.updatePlayer(response));
            return response;
        } catch (error) {
            dispatch(
                uiActions.showNotification({
                    status: "error",
                    title: "Error!",
                    message:
                        "Patching player scouting revised projection failed!",
                })
            );
            throw error;
        }
    };
};

export const patchPlayerIsDraftTarget = (playerId, isDraftTarget) => {
    return async (dispatch) => {
        const patchData = {
            is_draft_target: isDraftTarget,
        };

        try {
            const response = await fetchData(
                `${process.env.REACT_APP_HABSHUB_API_BASE_URL}hockey_analytics/players/${playerId}/isdrafttarget/`,
                "Failed to patch player scouting revised projection!",
                "PATCH",
                patchData
            );

            // Updating the player in the Redux store.
            dispatch(playersActions.updatePlayer(response));
            return response;
        } catch (error) {
            dispatch(
                uiActions.showNotification({
                    status: "error",
                    title: "Error!",
                    message: "Patching player is draft target failed!",
                })
            );
            throw error;
        }
    };
};

export const patchPlayerNote = (noteId, text) => {
    return async (dispatch) => {
        const patchData = {
            text: text,
        };

        try {
            const response = await fetchData(
                `${process.env.REACT_APP_HABSHUB_API_BASE_URL}hockey_analytics/playernotes/` +
                    noteId +
                    `/`,
                "Failed to patch player note!",
                "PATCH",
                patchData
            );

            // Updating the player notes updates in the Redux store.
            dispatch(playersActions.updatePlayerStatusUpdates([response]));
            return response;
        } catch (error) {
            dispatch(
                uiActions.showNotification({
                    status: "error",
                    title: "Error!",
                    message: "Patching player note failed!",
                })
            );
            throw error;
        }
    };
};

export const postPlayerStatusUpdate = (
    playerId,
    statusCategoryId,
    statusValueId,
    text,
    injury
) => {
    return async (dispatch) => {
        const postData = {
            player_id: playerId,
            status_category_id: statusCategoryId,
            status_value_id: statusValueId,
            text: text,
        };

        if (injury) {
            postData.injury = injury;
        }

        try {
            const response = await fetchData(
                `${process.env.REACT_APP_HABSHUB_API_BASE_URL}hockey_analytics/playerstatusupdates/`,
                "Failed to post player status update!",
                "POST",
                postData
            );

            // Updating the player status updates in the Redux store.
            dispatch(playersActions.updatePlayerStatusUpdates([response]));
            return response;
        } catch (error) {
            dispatch(
                uiActions.showNotification({
                    status: "error",
                    title: "Error!",
                    message: "Posting player status update failed!",
                })
            );
            throw error;
        }
    };
};

export const postPlayerDevelopmentPriority = (
    playerId,
    competitionId,
    seasonId,
    title,
    description,
    startDate,
    performanceMetricName
) => {
    return async (dispatch) => {
        const postData = {
            player_id: playerId,
            competition_id: competitionId,
            season_id: seasonId,
            title: title,
            description: description,
            start_date: startDate,
            performance_metric_name: performanceMetricName,
        };

        try {
            const response = await fetchData(
                `${process.env.REACT_APP_HABSHUB_API_BASE_URL}hockey_analytics/playerdevelopmentpriorities/`,
                "Failed to post player development priority!",
                "POST",
                postData
            );

            // Updating the player development priorities in the Redux store.
            dispatch(
                playersActions.updatePlayerDevelopmentPriorities([response])
            );
            return response;
        } catch (error) {
            dispatch(
                uiActions.showNotification({
                    status: "error",
                    title: "Error!",
                    message: "Posting player development priority failed!",
                })
            );
            throw error;
        }
    };
};

export const updatePlayerDevelopmentPriority = (
    priorityId,
    title,
    description,
    startDate,
    performanceMetricName
) => {
    return async (dispatch) => {
        const patchData = {
            title: title,
            description: description,
            start_date: startDate,
            performance_metric_name: performanceMetricName,
        };

        try {
            const response = await fetchData(
                `${process.env.REACT_APP_HABSHUB_API_BASE_URL}hockey_analytics/playerdevelopmentpriorities/${priorityId}/`,
                "Failed to patch player development priority!",
                "PATCH",
                patchData
            );

            // Updating the player development priorities in the Redux store.
            dispatch(
                playersActions.updatePlayerDevelopmentPriorities([response])
            );
            return response;
        } catch (error) {
            dispatch(
                uiActions.showNotification({
                    status: "error",
                    title: "Error!",
                    message: "Patching player development priority failed!",
                })
            );
            throw error;
        }
    };
};

export const closePlayerDevelopmentPriority = (
    priorityId,
    outcomeId,
    endDate,
    notes
) => {
    return async (dispatch) => {
        const postNoteData = {
            developmentpriority_id: priorityId,
            text: notes,
        };

        const patchDevelopmentPriorityData = {
            outcome_id: outcomeId,
            end_date: endDate,
            notes: notes,
        };

        try {
            // If notes is not null or empty string, post the note.
            if (notes) {
                const postNoteResponse = await fetchData(
                    `${process.env.REACT_APP_HABSHUB_API_BASE_URL}hockey_analytics/playerdevelopmentnotes/`,
                    "Failed to post player development note!",
                    "POST",
                    postNoteData
                );

                dispatch(
                    playersActions.updatePlayerDevelopmentNotes([
                        postNoteResponse,
                    ])
                );
            }

            const patchDevelopmentPriorityResponse = await fetchData(
                `${process.env.REACT_APP_HABSHUB_API_BASE_URL}hockey_analytics/playerdevelopmentpriorities/${priorityId}/`,
                "Failed to close player development priority!",
                "PATCH",
                patchDevelopmentPriorityData
            );

            // Updating the player development priorities in the Redux store.
            dispatch(
                playersActions.updatePlayerDevelopmentPriorities([
                    patchDevelopmentPriorityResponse,
                ])
            );
            return patchDevelopmentPriorityResponse;
        } catch (error) {
            dispatch(
                uiActions.showNotification({
                    status: "error",
                    title: "Error!",
                    message: "Closing player development priority failed!",
                })
            );
            throw error;
        }
    };
};

export const postPlayerInteraction = (
    playerId,
    interactionTypeId,
    interactionTone,
    interactionDate,
    interactionDuration,
    wasPlayerInstigated,
    interactionNotes,
    ownerId
) => {
    return async (dispatch) => {
        const postData = {
            player_id: playerId,
            interaction_type_id: interactionTypeId,
            date: interactionDate,
            duration_minutes: interactionDuration,
            was_player_instigated: wasPlayerInstigated,
            text: interactionNotes,
        };

        if (interactionTone !== null) {
            postData.tone = interactionTone;
        }

        if (ownerId) {
            postData.owner_id = ownerId;
        }

        try {
            const response = await fetchData(
                `${process.env.REACT_APP_HABSHUB_API_BASE_URL}hockey_analytics/playerinteractions/`,
                "Failed to post player interaction!",
                "POST",
                postData
            );

            // Updating the player interaction in the Redux store.
            dispatch(playersActions.updatePlayerInteractions([response]));
            return response;
        } catch (error) {
            dispatch(
                uiActions.showNotification({
                    status: "error",
                    title: "Error!",
                    message: "Posting player interaction failed!",
                })
            );
            throw error;
        }
    };
};

export const patchPlayerInteraction = (
    interactionId,
    interactionTypeId,
    interactionTone,
    interactionDate,
    interactionDuration,
    wasPlayerInstigated,
    interactionNotes
) => {
    return async (dispatch) => {
        const patchData = {
            interaction_type_id: interactionTypeId,
            date: interactionDate,
            duration_minutes: interactionDuration,
            was_player_instigated: wasPlayerInstigated,
        };

        if (interactionTone !== null) {
            patchData.tone = interactionTone;
        }

        if (interactionNotes !== null) {
            patchData.text = interactionNotes;
        }

        try {
            const response = await fetchData(
                `${process.env.REACT_APP_HABSHUB_API_BASE_URL}hockey_analytics/playerinteractions/${interactionId}/`,
                "Failed to patch player interaction!",
                "PATCH",
                patchData
            );

            // Updating the player interactions in the Redux store.
            dispatch(playersActions.updatePlayerInteractions([response]));
            return response;
        } catch (error) {
            dispatch(
                uiActions.showNotification({
                    status: "error",
                    title: "Error!",
                    message: "Patching player interaction failed!",
                })
            );
            throw error;
        }
    };
};

export const postPlayerDevelopmentSession = (
    playerId,
    extraPlayerIds,
    sessionTypeId,
    sessionPriorityIds,
    sessionDate,
    sessionDuration,
    wasPlayerInstigated,
    sessionNotes
) => {
    return async (dispatch) => {
        const postData = {
            player_id: playerId,
            extra_player_ids: extraPlayerIds || [],
            developmentpriority_ids: sessionPriorityIds,
            interaction_type_id: sessionTypeId,
            date: sessionDate,
            duration_minutes: sessionDuration,
            was_player_instigated: wasPlayerInstigated,
            text: sessionNotes,
        };

        try {
            const response = await fetchData(
                `${process.env.REACT_APP_HABSHUB_API_BASE_URL}hockey_analytics/playerdevelopmentsessions/`,
                "Failed to post player development session!",
                "POST",
                postData
            );

            // Updating the player development sessions in the Redux store.
            dispatch(playersActions.updatePlayerDevelopmentSessions(response));
            return response;
        } catch (error) {
            dispatch(
                uiActions.showNotification({
                    status: "error",
                    title: "Error!",
                    message: "Posting player development session failed!",
                })
            );
            throw error;
        }
    };
};

export const patchPlayerDevelopmentSession = (
    sessionId,
    sessionTypeId,
    sessionPriorityIds,
    sessionDate,
    sessionDuration,
    wasPlayerInstigated,
    sessionNotes
) => {
    return async (dispatch) => {
        const patchData = {
            interaction_type_id: sessionTypeId,
            developmentpriority_ids: sessionPriorityIds,
            date: sessionDate,
            duration_minutes: sessionDuration,
            was_player_instigated: wasPlayerInstigated,
            text: sessionNotes,
        };

        try {
            const response = await fetchData(
                `${process.env.REACT_APP_HABSHUB_API_BASE_URL}hockey_analytics/playerdevelopmentsessions/${sessionId}/`,
                "Failed to patch player development session!",
                "PATCH",
                patchData
            );

            // Updating the player development sessions in the Redux store.
            dispatch(
                playersActions.updatePlayerDevelopmentSessions([response])
            );
            return response;
        } catch (error) {
            dispatch(
                uiActions.showNotification({
                    status: "error",
                    title: "Error!",
                    message: "Patching player development session failed!",
                })
            );
            throw error;
        }
    };
};

export const fetchTeamLatestPlayerStatusUpdates = (teamId, tier) => {
    return async (dispatch) => {
        if (!ongoingRequests.fetchTeamLatestPlayerStatusUpdates[teamId]) {
            ongoingRequests.fetchTeamLatestPlayerStatusUpdates[teamId] =
                fetchData(
                    process.env.REACT_APP_HABSHUB_API_BASE_URL +
                        "hockey_analytics/playerstatusupdates/?teamId=" +
                        teamId +
                        "&tier=" +
                        tier +
                        "&latest_by_category=true",

                    "Could not fetch team latest player status updates!"
                )
                    .then((latestStatusUpdatesData) => {
                        dispatch(
                            playersActions.updatePlayerStatusUpdates(
                                latestStatusUpdatesData
                            )
                        );
                        delete ongoingRequests
                            .fetchTeamLatestPlayerStatusUpdates[teamId];
                        return latestStatusUpdatesData;
                    })
                    .catch((error) => {
                        dispatch(
                            uiActions.showNotification({
                                status: "error",
                                title: "Error!",
                                message:
                                    "Fetching team latest player status updates data failed!",
                            })
                        );
                        delete ongoingRequests
                            .fetchTeamLatestPlayerStatusUpdates[teamId];
                        throw error;
                    });
        }

        return ongoingRequests.fetchTeamLatestPlayerStatusUpdates[teamId];
    };
};

export const fetchTeamPlayerInjuries = (teamId, tier) => {
    return async (dispatch) => {
        if (!ongoingRequests.fetchTeamPlayerInjuries[teamId]) {
            ongoingRequests.fetchTeamPlayerInjuries[teamId] = fetchData(
                process.env.REACT_APP_HABSHUB_API_BASE_URL +
                    "hockey_analytics/playerinjuries/?teamId=" +
                    teamId +
                    "&tier=" +
                    tier,

                "Could not fetch team injuries!"
            )
                .then((injuriesData) => {
                    dispatch(playersActions.updatePlayerInjuries(injuriesData));
                    delete ongoingRequests.fetchTeamPlayerInjuries[teamId];
                    return injuriesData;
                })
                .catch((error) => {
                    dispatch(
                        uiActions.showNotification({
                            status: "error",
                            title: "Error!",
                            message: "Fetching team injuries data failed!",
                        })
                    );
                    delete ongoingRequests.fetchTeamPlayerInjuries[teamId];
                    throw error;
                });
        }

        return ongoingRequests.fetchTeamPlayerInjuries[teamId];
    };
};

export const fetchTeamPlayerDevelopmentPriorities = (
    teamId,
    tier,
    isActive
) => {
    return async (dispatch) => {
        if (!ongoingRequests.fetchTeamDevelopmentPriorities[teamId]) {
            ongoingRequests.fetchTeamDevelopmentPriorities[teamId] = fetchData(
                process.env.REACT_APP_HABSHUB_API_BASE_URL +
                    "hockey_analytics/playerdevelopmentpriorities/?teamId=" +
                    teamId +
                    "&tier=" +
                    tier +
                    (isActive !== undefined ? "&active=" + isActive : ""),

                "Could not fetch team player development priorities!"
            )
                .then((playerDevelpmentDevelopmentPrioritiesData) => {
                    dispatch(
                        playersActions.updatePlayerDevelopmentPriorities(
                            playerDevelpmentDevelopmentPrioritiesData
                        )
                    );
                    delete ongoingRequests.fetchTeamDevelopmentPriorities[
                        teamId
                    ];
                    return playerDevelpmentDevelopmentPrioritiesData;
                })
                .catch((error) => {
                    dispatch(
                        uiActions.showNotification({
                            status: "error",
                            title: "Error!",
                            message:
                                "Fetching team player development priorities data failed!",
                        })
                    );
                    delete ongoingRequests.fetchTeamDevelopmentPriorities[
                        teamId
                    ];
                    throw error;
                });
        }

        return ongoingRequests.fetchTeamDevelopmentPriorities[teamId];
    };
};

export const fetchPlayersContracts = (
    playerIds,
    minSeasonId,
    nhlReserveListTeamId
) => {
    return async (dispatch) => {
        // Create a unique key for each playerIds and minSeasonId combination
        const key =
            (playerIds ? playerIds.join(",") : "all") +
            "-" +
            (minSeasonId ? minSeasonId : "all") +
            "-" +
            (nhlReserveListTeamId ? nhlReserveListTeamId : "all");

        const params = [
            playerIds && playerIds.map((id) => `player_ids=${id}`).join("&"),
            minSeasonId && `min_season=${minSeasonId}`,
            nhlReserveListTeamId &&
                `nhl_reserve_list_team_id=${nhlReserveListTeamId}`,
        ]
            .filter(Boolean)
            .join("&");

        if (!ongoingRequests.fetchPlayersContracts[key]) {
            ongoingRequests.fetchPlayersContracts[key] = fetchData(
                process.env.REACT_APP_HABSHUB_API_BASE_URL +
                    "hockey_analytics/playercontracts/" +
                    (params ? `?${params}` : ""),
                "Could not fetch players data!"
            )
                .then((data) => {
                    dispatch(
                        playersActions.updatePlayersContracts({
                            data: data,
                        })
                    );
                    delete ongoingRequests.fetchPlayersContracts[key];
                    return data;
                })
                .catch((error) => {
                    dispatch(
                        uiActions.showNotification({
                            status: "error",
                            title: "Error!",
                            message: `Fetching player contracts for players ${
                                playerIds ? playerIds.join(",") : ""
                            } failed!`,
                        })
                    );
                    delete ongoingRequests.fetchPlayersContracts[key];
                    throw error;
                });
        }

        return ongoingRequests.fetchPlayersContracts[key];
    };
};

export const fetchPlayerNews = (
    playerId = null,
    teamId = null,
    limit = null
) => {
    return async (dispatch) => {
        // Create a unique key based on provided parameters
        const requestKey = `${playerId || "all"}-${teamId || "all"}`;

        if (!ongoingRequests.fetchPlayerNews[requestKey]) {
            // Build query parameters
            const params = [];
            if (playerId) params.push(`player_id=${playerId}`);
            if (teamId) params.push(`team_id=${teamId}`);
            if (limit) params.push(`limit=${limit}`);

            const queryString = params.length > 0 ? `?${params.join("&")}` : "";

            ongoingRequests.fetchPlayerNews[requestKey] = fetchData(
                process.env.REACT_APP_HABSHUB_API_BASE_URL +
                    "hockey_analytics/playernews/" +
                    queryString,
                "Could not fetch news!"
            )
                .then((newsData) => {
                    dispatch(playersActions.updatePlayerNews(newsData));
                    delete ongoingRequests.fetchPlayerNews[requestKey];
                    return newsData;
                })
                .catch((error) => {
                    dispatch(
                        uiActions.showNotification({
                            status: "error",
                            title: "Error!",
                            message: "Fetching news data failed!",
                        })
                    );
                    delete ongoingRequests.fetchPlayerNews[requestKey];
                    throw error;
                });
        }

        return ongoingRequests.fetchPlayerNews[requestKey];
    };
};
