import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchTeam, fetchTeamDatastash } from "../store/teams-actions";
import { uiActions } from "../store/ui-slice";
import AppPage from "../layout/AppPage";
import CompressiblePanel from "../layout/CompressiblePanel";
import OrgHeader from "../layout/OrgHeader";
import MainContent from "../layout/MainContent";
import PlayerTradStatsTileLegend from "../components/legends/PlayerTradStatsTileLegend";
import ScoutingTileLegend from "../components/legends/ScoutingTileLegend";
import PlayerPerformanceTileLegend from "../components/legends/PlayerPerformanceTileLegend";
import PlayerCapTileLegend from "../components/legends/PlayerCapTileLegend";
import DepthChartView from "../components/DepthChartView";

import classes from "./TeamRosterPages.module.css";

const StaticDepthChartPage = ({ teamId = 16 }) => {
    const dispatch = useDispatch();

    const [pageViewContentLoading, setPageViewContentLoading] = useState(true);

    const teams = useSelector((state) => state.teams.teams);
    const team = teams && teams[teamId];

    const tileViewMode = useSelector((state) => state.ui.playerTileMode);

    const teams_datastashes = useSelector((state) => state.teams.datastashes);
    const depth_chart_config =
        teams_datastashes &&
        teams_datastashes[teamId] &&
        teams_datastashes[teamId].depth_chart_config;

    // Fetch required data
    useEffect(() => {
        if (!teams[teamId]) {
            dispatch(fetchTeam(teamId));
            dispatch(uiActions.showLoader());
        }
    }, [teamId, teams, dispatch]);

    useEffect(() => {
        if (teams && team && !teams[team.affiliations[0]]) {
            dispatch(fetchTeam(team.affiliations[0]));
            dispatch(uiActions.showLoader());
        }
    }, [team, teams, dispatch]);

    useEffect(() => {
        if (!depth_chart_config) {
            dispatch(fetchTeamDatastash(teamId, "depth_chart_config"));
            dispatch(uiActions.showLoader());
        }
    }, [teamId, depth_chart_config, dispatch]);

    useEffect(() => {
        if (team && depth_chart_config) {
            dispatch(uiActions.hideLoader());
        }
    }, [team, depth_chart_config, dispatch]);

    useEffect(() => {
        if (!pageViewContentLoading) {
            dispatch(uiActions.hideLoader());
        }
    }, [pageViewContentLoading, dispatch]);

    const headerTitle = "Depth Chart";

    const [mainContentWidth, setMainContentWidth] = useState(null);

    return (
        <AppPage>
            <CompressiblePanel
                compressWidth={0}
                onWidthChange={setMainContentWidth}
            >
                <>
                    {tileViewMode === "trad_stats" && (
                        <PlayerTradStatsTileLegend
                            zIndex={4}
                            includeTrends={true}
                            includeFreeAgency={true}
                        />
                    )}
                    {tileViewMode === "scouting" && (
                        <ScoutingTileLegend
                            includeFreeAgency={true}
                            zIndex={4}
                        />
                    )}
                    {tileViewMode === "performance" && (
                        <PlayerPerformanceTileLegend
                            zIndex={4}
                            includeTrends={true}
                            includeFreeAgency={true}
                        />
                    )}
                    {tileViewMode === "cap" && (
                        <PlayerCapTileLegend zIndex={4} />
                    )}
                </>
                <OrgHeader
                    fullTitle={
                        (team ? team.location + " " + team.name : "") +
                        " " +
                        headerTitle
                    }
                    mediumTitle={
                        (team ? team.location : "") + " " + headerTitle
                    }
                    shortTitle={headerTitle}
                    bgColor={team ? team.primary_colour : "#000"}
                    imgUrl={team ? team.alt_logo_url : ""}
                    imgPosition={
                        depth_chart_config
                            ? depth_chart_config.header_object_position
                            : "50% 50%"
                    }
                    parentWidth={mainContentWidth}
                />
                <MainContent>
                    <div className={classes.view_container}>
                        <DepthChartView
                            teamId={teamId}
                            onContentLoading={setPageViewContentLoading}
                            altDatastash={'static_depth_chart'}
                        />
                    </div>
                </MainContent>
            </CompressiblePanel>
        </AppPage>
    );
};

export default StaticDepthChartPage;
