import { useEffect, useState, useCallback, useRef } from "react";
import "./styles.module.css";
import { useDispatch, useSelector } from "react-redux";
import {
    fetchCompetitions,
    fetchCompetitionSeasonDatastash,
} from "./store/competitions-actions";
import { fetchSeasons } from "./store/seasons-actions";
import {
    fetchPlayerAgencies,
    fetchPlayerAgents,
} from "./store/players-actions";
import { playersActions } from "./store/players-slice";
import { fetchDrafts } from "./store/playerboards-actions";
import FadeablePanel from "./layout/FadeablePanel";
import PushablePanel from "./layout/PushablePanel";
import useAuth from "./hooks/use-auth";
import useCrossPageViewContext from "./hooks/use-cross-page-view-context";
import { uiActions } from "./store/ui-slice";

import {
    Route,
    Routes,
    useParams,
    useSearchParams,
    useNavigate,
} from "react-router-dom";
import "./App.css";

import NavMenuPanel from "./components/nav-menu-panel/NavMenuPanel";

import PlayerPage from "./app-pages/PlayerPage";
import TeamRosterPages from "./app-pages/TeamRosterPages";
import TeamPlayerAvailability from "./app-pages/TeamPlayerAvailability";
import TeamPlayerInteractions from "./app-pages/TeamPlayerInteractions";
import TeamPlayerDevelopment from "./app-pages/TeamPlayerDevelopment";
import PreScout from "./app-pages/PreScout";
import DraftBoard from "./components/DraftBoard";
import DraftBoardMarker from "./components/DraftBoardMarker";
import ScoutingBoards from "./components/ScoutingBoards";
import HistoricalTrends from "./app-pages/HistoricalTrends";
import PlayerSearchPage from "./app-pages/PlayerSearchPage";
import StaticDepthChartPage from "./app-pages/StaticDepthChartPage";
import PlayerPracticeSurvey from "./app-pages/PlayerPracticeSurvey";

// Wrappers are used so that the components do not have to be aware of path
// parameters, which can be passed to them as props

function PlayerPageParamWrapper({ onMenuOpen }) {
    const navigate = useNavigate();
    const { player_id = null } = useParams();
    const playerId = parseInt(player_id); // Convert id from string to number

    // Retrieve and set query parameters
    const [searchParams] = useSearchParams();

    const urlRef = useRef();

    const updateQueryParams = useCallback(
        ({ playerId, seasonId, competitionId, metricContext, backLink }) => {
            let newPath = `/app/players/${playerId}/performance`;
            const newSearchParams = new URLSearchParams();

            // Only add parameters if they are present
            if (seasonId) {
                newSearchParams.set("seasonId", seasonId.toString());
            }
            if (competitionId) {
                newSearchParams.set("competitionId", competitionId.toString());
            }
            if (
                metricContext &&
                metricContext.stack &&
                metricContext.activeMetric
            ) {
                // Metric stack is a list of strings, so it needs to be
                // converted to a single string to be passed as a query parameter
                newSearchParams.set(
                    "metricStack",
                    metricContext.stack.join(",")
                );
                newSearchParams.set(
                    "selectedMetric",
                    metricContext.activeMetric
                );
            }
            if (backLink) {
                newSearchParams.set("backNavOrigin", backLink);
            }

            const url = `${newPath}?${newSearchParams.toString()}`;

            if (url !== urlRef.current) {
                urlRef.current = url;
                // Use the `navigate` function to update the URL and create a history entry
                navigate(url, {
                    replace: true,
                });
            }
        },
        [navigate]
    );

    return (
        <PlayerPage
            playerId={playerId}
            seasonId={parseInt(searchParams.get("seasonId")) || null}
            competitionId={parseInt(searchParams.get("competitionId")) || null}
            metricContext={{
                stack: searchParams.get("metricStack")?.split(",") || null,
                activeMetric: searchParams.get("selectedMetric") || null,
            }}
            onMenuOpen={onMenuOpen}
            backLink={searchParams.get("backNavOrigin") || null}
            onViewContextChange={updateQueryParams}
        />
    );
}

function TeamRosterPathParamWrapper({
    initialPageClass,
    onMenuOpen,
    onTeamSelectionChange,
}) {
    const navigate = useNavigate();
    const { viewContext, setViewContext } = useCrossPageViewContext();

    const { team_id = "16" } = useParams();
    const initialTeamId = parseInt(team_id); // Convert id from string to number

    useEffect(() => {
        onTeamSelectionChange(initialTeamId);
    }, [initialTeamId, onTeamSelectionChange]);

    const handleSelectionChange = (newPageState) => {
        navigate(`/app/${newPageState.pageClass}/${newPageState.teamId}`);
    };

    return (
        <TeamRosterPages
            pageClass={initialPageClass}
            teamId={initialTeamId}
            viewContextProp={viewContext}
            onViewContextChange={setViewContext}
            onMenuOpen={onMenuOpen}
            onSelectionChange={handleSelectionChange}
        />
    );
}

function TeamPlayerInteractionsPathParamWrapper({ onMenuOpen }) {
    const { viewContext, setViewContext } = useCrossPageViewContext(true);

    const { team_id = "16" } = useParams();
    const teamId = parseInt(team_id); // Convert id from string to number

    return (
        <TeamPlayerInteractions
            teamId={teamId}
            tier={"All"}
            viewContextProp={viewContext}
            onViewContextChange={setViewContext}
            onMenuOpen={onMenuOpen}
        />
    );
}

function TeamPlayerDevelopmentPathParamWrapper({ onMenuOpen }) {
    const { viewContext, setViewContext } = useCrossPageViewContext(true);

    const { team_id = "16" } = useParams();
    const teamId = parseInt(team_id); // Convert id from string to number

    return (
        <TeamPlayerDevelopment
            teamId={teamId}
            tier={"All"}
            viewContextProp={viewContext}
            onViewContextChange={setViewContext}
            onMenuOpen={onMenuOpen}
        />
    );
}

function TeamPlayerAvailabilityPathParamWrapper({ onMenuOpen }) {
    const { viewContext, setViewContext } = useCrossPageViewContext(true);

    const { team_id = "16" } = useParams();
    const teamId = parseInt(team_id); // Convert id from string to number

    return (
        <TeamPlayerAvailability
            teamId={teamId}
            tier={"NHL"}
            viewContextProp={viewContext}
            onViewContextChange={setViewContext}
            onMenuOpen={onMenuOpen}
        />
    );
}

function HistoricalTrendsParamWrapper({ onMenuOpen }) {
    const { viewContext, setViewContext } = useCrossPageViewContext(true);

    return (
        <HistoricalTrends
            viewContextProp={viewContext}
            onViewContextChange={setViewContext}
            onMenuOpen={onMenuOpen}
        />
    );
}

function PreScoutPathParamWrapper({ onMenuOpen, onTeamSelectionChange }) {
    const { team_id = "1" } = useParams();
    const teamId = parseInt(team_id); // Convert id from string to number

    useEffect(() => {
        onTeamSelectionChange(teamId);
    }, [teamId, onTeamSelectionChange]);

    return <PreScout initialTeamId={teamId} onMenuOpen={onMenuOpen} />;
}

function ScoutingBoardsPathParamWrapper({ onMenuOpen }) {
    const { viewContext, setViewContext } = useCrossPageViewContext();
    const { board_slug = "ufa" } = useParams();

    return (
        <ScoutingBoards
            initialBoardSlug={board_slug}
            viewContextProp={viewContext}
            onViewContextChange={setViewContext}
            onMenuOpen={onMenuOpen}
        />
    );
}

function DraftBoardPathParamWrapper({ onMenuOpen }) {
    const { viewContext, setViewContext } = useCrossPageViewContext(true);
    const { id } = useParams();

    return (
        <DraftBoard
            initialDraftId={parseInt(id)}
            viewContextProp={viewContext}
            onViewContextChange={setViewContext}
            onMenuOpen={onMenuOpen}
        />
    );
}

function DraftBoardMarkerPathParamWrapper({ onMenuOpen }) {
    const { id = "3" } = useParams();
    const draftId = parseInt(id); // Convert id from string to number

    return (
        <DraftBoardMarker
            initialDraftId={draftId}
            boardCompetitionId={1}
            onMenuOpen={onMenuOpen}
        />
    );
}

function PlayerSearchPageParamWrapper({ onMenuOpen }) {
    const navigate = useNavigate();

    // Retrieve and set query parameters
    const [searchParams] = useSearchParams();

    const updateQueryParams = useCallback(
        ({ query }) => {
            let newPath = `/app/playersearch`;
            const newSearchParams = new URLSearchParams();

            // Only add parameters if they are present
            if (query) {
                newSearchParams.set("query", query.toString());
            }

            // Use the `navigate` function to update the URL and create a history entry
            navigate(`${newPath}?${newSearchParams.toString()}`, {
                replace: false,
            });
        },
        [navigate]
    );

    return (
        <PlayerSearchPage
            query={searchParams.get("query") || ""}
            onQueryUpdate={updateQueryParams}
            onMenuOpen={onMenuOpen}
        />
    );
}

function App() {
    const { isAuthenticated, user, checkPermission } = useAuth();
    const dispatch = useDispatch();

    const canAccessTeamPerformancePages = checkPermission(
        "core.can_access_team_performance_pages"
    );
    const canAccessPlayerPerformancePages = checkPermission(
        "core.can_access_player_performance_pages"
    );
    const canAccessPlayerAvailabilityPages = checkPermission(
        "core.can_access_player_availability_pages"
    );
    const canAccessPlayerInteractionsPages = checkPermission(
        "core.can_access_player_interactions_pages"
    );
    const canAccessPlayerDevelopmentPages = checkPermission(
        "core.can_access_player_development_pages"
    );
    const canAccessScoutingBoards = checkPermission(
        "core.can_access_scouting_boards"
    );

    const contractViewsSeasonId = useSelector(
        (state) => state.ui.systemConfiguration?.contractViewsSeasonId
    );

    const [selectedTeamId, setSelectedTeamId] = useState(16);

    useEffect(() => {
        dispatch(fetchCompetitions());
        dispatch(fetchSeasons());
        if (contractViewsSeasonId) {
            dispatch(
                fetchCompetitionSeasonDatastash(
                    1,
                    contractViewsSeasonId,
                    "salary_cap_limits"
                )
            );
        }
        if (canAccessScoutingBoards) {
            dispatch(fetchDrafts());
        }
        dispatch(fetchPlayerAgencies());
        dispatch(fetchPlayerAgents());
    }, [contractViewsSeasonId, canAccessScoutingBoards, dispatch]);

    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const onMenuOpenHandler = () => {
        setIsMenuOpen(true);
    };

    const onMenuCloseHandler = () => {
        setIsMenuOpen(false);
    };

    // Using a map to manage multiple WebSocket connections
    const [webSockets, setWebSockets] = useState(new Map());

    // Function to setup event listeners for a WebSocket
    const setupWebSocketEvents = useCallback(
        (websocket, entity, updateFunc) => {
            websocket.onopen = () => {
                console.log(`${entity} Updates WebSocket connected.`);
            };

            websocket.onmessage = (event) => {
                const data = JSON.parse(event.data);
                console.log(`${entity} WebSocket update received.`);

                dispatch(updateFunc(data));
            };

            websocket.onclose = () => {
                console.log(`${entity} WebSocket disconnected.`);
            };
        },
        [dispatch]
    );

    const [shouldUpdateWebSockets, setShouldUpdateWebSockets] = useState(false);

    // Determine when to update the WebSockets
    useEffect(() => {
        if (isAuthenticated) {
            setShouldUpdateWebSockets(true);
        }
    }, [isAuthenticated]);

    // Effect for setting up WebSocket connections
    useEffect(() => {
        if (shouldUpdateWebSockets) {
            // Close existing connections
            webSockets.forEach((ws) => ws.close());
            const newWebSockets = new Map();

            // Create new connections
            const playersWebsocket = new WebSocket(
                process.env.REACT_APP_HABSHUB_WS_BASE_URL + `players/`
            );

            // Setup event listeners for each WebSocket
            setupWebSocketEvents(playersWebsocket, "Players", (data) =>
                playersActions.updatePlayer(data.player)
            );

            newWebSockets.set(1, playersWebsocket);

            const configurationsWebsocket = new WebSocket(
                process.env.REACT_APP_HABSHUB_WS_BASE_URL + `configurations/`
            );

            // Setup event listeners for each WebSocket
            setupWebSocketEvents(
                configurationsWebsocket,
                "Configurations",
                uiActions.updateSystemConfiguration
            );

            newWebSockets.set(2, configurationsWebsocket);

            setWebSockets(newWebSockets);
            setShouldUpdateWebSockets(false); // Reset the trigger
        }

        // Cleanup function to close all connections
        return () => {
            webSockets.forEach((ws) => ws.close());
        };
    }, [shouldUpdateWebSockets, setupWebSocketEvents, webSockets]);

    return (
        <>
            <NavMenuPanel
                selectedTeamId={selectedTeamId}
                isOpen={isMenuOpen}
                onClose={onMenuCloseHandler}
            />
            <FadeablePanel isFaded={isMenuOpen} onClick={onMenuCloseHandler}>
                <PushablePanel isPushed={isMenuOpen} pushAmount={"100px"}>
                    <Routes>
                        <Route
                            path="/app/historicaltrends"
                            element={
                                user && user.is_staff ? (
                                    <HistoricalTrendsParamWrapper
                                        onMenuOpen={onMenuOpenHandler}
                                    />
                                ) : (
                                    <div>Unauthorized</div>
                                )
                            }
                        />
                        {canAccessTeamPerformancePages && (
                            <Route
                                path="/app/prescout/:team_id?"
                                element={
                                    <PreScoutPathParamWrapper
                                        onMenuOpen={onMenuOpenHandler}
                                        onTeamSelectionChange={
                                            setSelectedTeamId
                                        }
                                    />
                                }
                            />
                        )}
                        {canAccessPlayerPerformancePages && (
                            <>
                                <Route
                                    path="/app/players/:player_id/performance"
                                    element={
                                        <PlayerPageParamWrapper
                                            onMenuOpen={onMenuOpenHandler}
                                        />
                                    }
                                />
                                <Route
                                    path="/app/playerimpact/:team_id?"
                                    element={
                                        <TeamRosterPathParamWrapper
                                            initialPageClass="playerimpact"
                                            onMenuOpen={onMenuOpenHandler}
                                            onTeamSelectionChange={
                                                setSelectedTeamId
                                            }
                                        />
                                    }
                                />
                                <Route
                                    path="/app/playerseasonperformance/:team_id?"
                                    element={
                                        <TeamRosterPathParamWrapper
                                            initialPageClass="playerseasonperformance"
                                            onMenuOpen={onMenuOpenHandler}
                                            onTeamSelectionChange={
                                                setSelectedTeamId
                                            }
                                        />
                                    }
                                />
                                <Route
                                    path="/app/depthchart/:team_id?"
                                    element={
                                        <TeamRosterPathParamWrapper
                                            initialPageClass="depthchart"
                                            onMenuOpen={onMenuOpenHandler}
                                            onTeamSelectionChange={
                                                setSelectedTeamId
                                            }
                                        />
                                    }
                                />
                                <Route
                                    path="/app/rosteredit/:team_id?"
                                    element={
                                        <TeamRosterPathParamWrapper
                                            initialPageClass="rosteredit"
                                            onMenuOpen={onMenuOpenHandler}
                                            onTeamSelectionChange={
                                                setSelectedTeamId
                                            }
                                        />
                                    }
                                />
                                <Route
                                    path="/app/capreport/:team_id?"
                                    element={
                                        <TeamRosterPathParamWrapper
                                            initialPageClass="capreport"
                                            onMenuOpen={onMenuOpenHandler}
                                            onTeamSelectionChange={
                                                setSelectedTeamId
                                            }
                                        />
                                    }
                                />
                                <Route
                                    path="/app/teamnews/:team_id?"
                                    element={
                                        <TeamRosterPathParamWrapper
                                            initialPageClass="teamnews"
                                            onMenuOpen={onMenuOpenHandler}
                                            onTeamSelectionChange={
                                                setSelectedTeamId
                                            }
                                        />
                                    }
                                />
                                <Route
                                    path="/app/playersearch"
                                    element={
                                        <PlayerSearchPageParamWrapper
                                            onMenuOpen={onMenuOpenHandler}
                                        />
                                    }
                                />
                            </>
                        )}
                        {canAccessPlayerAvailabilityPages && (
                            <Route
                                path="/app/playeravailability/:team_id?"
                                element={
                                    <TeamPlayerAvailabilityPathParamWrapper
                                        onMenuOpen={onMenuOpenHandler}
                                    />
                                }
                            />
                        )}
                        {canAccessPlayerInteractionsPages && (
                            <Route
                                path="/app/playerinteractions/:team_id?"
                                element={
                                    <TeamPlayerInteractionsPathParamWrapper
                                        onMenuOpen={onMenuOpenHandler}
                                    />
                                }
                            />
                        )}
                        {canAccessPlayerDevelopmentPages && (
                            <Route
                                path="/app/playerdevelopment/:team_id?"
                                element={
                                    <TeamPlayerDevelopmentPathParamWrapper
                                        onMenuOpen={onMenuOpenHandler}
                                    />
                                }
                            />
                        )}
                        {canAccessScoutingBoards && (
                            <>
                                <Route
                                    path="/app/draft/:id/mark"
                                    element={
                                        <DraftBoardMarkerPathParamWrapper
                                            onMenuOpen={onMenuOpenHandler}
                                        />
                                    }
                                />
                                <Route
                                    path="/app/draft/:id?"
                                    element={
                                        <DraftBoardPathParamWrapper
                                            onMenuOpen={onMenuOpenHandler}
                                        />
                                    }
                                />
                                <Route
                                    path="/app/scoutingboards/:board_slug?"
                                    element={
                                        <ScoutingBoardsPathParamWrapper
                                            onMenuOpen={onMenuOpenHandler}
                                        />
                                    }
                                />
                            </>
                        )}
                        <Route
                            path="/app/practicesurvey"
                            element={<PlayerPracticeSurvey />}
                        />
                        <Route
                            path="/app/temp/"
                            element={<StaticDepthChartPage />}
                        />
                    </Routes>
                </PushablePanel>
            </FadeablePanel>
        </>
    );
}

export default App;
