import { uiActions } from "./ui-slice";
import { fetchData } from "../utils";
import { surveysActions } from "./surveys-slice";

let ongoingRequests = {
    fetchSurveys: null,
    fetchSurveyDetails: {},
    fetchSurveyResults: {},
    postSurveyResult: {},
};

export const fetchSurveys = () => {
    return async (dispatch) => {
        if (!ongoingRequests.fetchSurveys) {
            ongoingRequests.fetchSurveys = fetchData(
                process.env.REACT_APP_HABSHUB_API_BASE_URL + "hockey_analytics/surveys/",
                "Could not fetch surveys!"
            )
                .then((surveysData) => {
                    dispatch(surveysActions.updateSurveys(surveysData));
                    ongoingRequests.fetchSurveys = null;
                    return surveysData;
                })
                .catch((error) => {
                    dispatch(
                        uiActions.showNotification({
                            status: "error",
                            title: "Error!",
                            message: "Fetching surveys failed!",
                        })
                    );
                    ongoingRequests.fetchSurveys = null;
                    throw error;
                });
        }
        return ongoingRequests.fetchSurveys;
    };
};

export const fetchSurveyDetails = (surveyId, language) => {
    return async (dispatch) => {
        const key = `${surveyId}-${language}`;
        if (!ongoingRequests.fetchSurveyDetails[key]) {
            ongoingRequests.fetchSurveyDetails[key] = fetchData(
                `${process.env.REACT_APP_HABSHUB_API_BASE_URL}hockey_analytics/surveys/${surveyId}/lang/${language}/`,
                "Could not fetch survey details!"
            )
                .then((detailsData) => {
                    dispatch(
                        surveysActions.updateSurveyDetails({
                            surveyId,
                            language,
                            details: detailsData,
                        })
                    );
                    delete ongoingRequests.fetchSurveyDetails[key];
                    return detailsData;
                })
                .catch((error) => {
                    dispatch(
                        uiActions.showNotification({
                            status: "error",
                            title: "Error!",
                            message: "Fetching survey details failed!",
                        })
                    );
                    delete ongoingRequests.fetchSurveyDetails[key];
                    throw error;
                });
        }
        return ongoingRequests.fetchSurveyDetails[key];
    };
};

export const fetchSurveyResults = (surveyId) => {
    return async (dispatch) => {
        if (!ongoingRequests.fetchSurveyResults[surveyId]) {
            ongoingRequests.fetchSurveyResults[surveyId] = fetchData(
                `${process.env.REACT_APP_HABSHUB_API_BASE_URL}hockey_analytics/surveys/${surveyId}/results/`,
                "Could not fetch survey results!"
            )
                .then((resultsData) => {
                    dispatch(
                        surveysActions.updateSurveyResults({
                            surveyId,
                            results: resultsData,
                        })
                    );
                    delete ongoingRequests.fetchSurveyResults[surveyId];
                    return resultsData;
                })
                .catch((error) => {
                    dispatch(
                        uiActions.showNotification({
                            status: "error",
                            title: "Error!",
                            message: "Fetching survey results failed!",
                        })
                    );
                    delete ongoingRequests.fetchSurveyResults[surveyId];
                    throw error;
                });
        }
        return ongoingRequests.fetchSurveyResults[surveyId];
    };
};

export const postSurveyResult = (surveyId, resultData) => {
    return async (dispatch) => {
        const key = `${surveyId}-${Date.now()}`;
        if (!ongoingRequests.postSurveyResult[key]) {
            ongoingRequests.postSurveyResult[key] = fetchData(
                `${process.env.REACT_APP_HABSHUB_API_BASE_URL}hockey_analytics/surveys/${surveyId}/results/`,
                "Could not submit survey result!",
                "POST",
                resultData
            )
                .then((response) => {
                    delete ongoingRequests.postSurveyResult[key];
                    return response;
                })
                .catch((error) => {
                    dispatch(
                        uiActions.showNotification({
                            status: "error",
                            title: "Error!",
                            message: "Submitting survey result failed!",
                        })
                    );
                    delete ongoingRequests.postSurveyResult[key];
                    throw error;
                });
        }
        return ongoingRequests.postSurveyResult[key];
    };
};