import classes from "./MainContent.module.css";

function MainContent({ fullWidth = false, children }) {
    return (
        <div
            className={
                classes.main_content +
                (fullWidth ? " " + classes.full_width : "")
            }
        >
            {children}
        </div>
    );
}

export default MainContent;
