import React, { useEffect, useState, useRef, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { uiActions } from "../store/ui-slice";
import { fetchPlayerboardData } from "../store/playerboards-actions";
import {
    fetchCompetition,
    fetchCompetitionActiveTeams,
} from "../store/competitions-actions";
import NavigationBar, {
    NavigationSetting,
    DraftSelector,
} from "../controls/NavigationBar";
import AppPage from "../layout/AppPage";
import OrgHeader from "../layout/OrgHeader";
import MainContent from "../layout/MainContent";
import CompressiblePanel from "../layout/CompressiblePanel";
import MainBox from "../layout/MainBox";
import { mp_track } from "../mixpanel";
import useAuth from "../hooks/use-auth";
import PlayerTeamSelector from "../controls/PlayerTeamSelector";
import Toggle from "../controls/buttons/Toggle";
import classes from "./DraftBoardMarker.module.css";
import { get_cookie } from "../utils";

const draft_board_marker_mp_track = (
    event,
    competition,
    season,
    properties = null
) => {
    properties = {
        ...properties,
        competition_id: competition.id,
        competition_name: competition.display_name,
        season_id: season.id,
        season_name: season.name,
    };

    mp_track(event, properties);
};

const chunkArray = (array, size) => {
    const chunked_arr = [];
    let copied = [...array]; // ES6 destructuring
    const numOfChild = Math.ceil(copied.length / size);
    for (let i = 0; i < numOfChild; i++) {
        chunked_arr.push(copied.splice(0, size));
    }
    return chunked_arr;
};

const DraftBoardMarker = ({
    initialDraftId,
    boardCompetitionId,
    onMenuOpen,
}) => {
    const navigate = useNavigate();
    const { isAuthenticated, checkPermission } = useAuth();

    const canEditDraftSelections = checkPermission(
        "core.can_edit_draft_selections"
    );

    const draftViewSeasonId = useSelector(
        (state) => state.ui.systemConfiguration?.draftViewSeasonId
    );

    const competitions = useSelector(
        (state) => state.competitions.competitions
    );
    const seasons = useSelector((state) => state.seasons.seasons);
    const drafts = useSelector((state) => state.playerboards.drafts);

    const [alphabeticalSort, setAlphabeticalSort] = useState(false);
    const [alphabeticalSortToggleState, setAlphabeticalSortToggleState] =
        useState(alphabeticalSort);
    const [listVisible, setListVisible] = useState(true);

    const onToggleAlphabeticalSort = () => {
        setListVisible(false);
        setAlphabeticalSortToggleState(!alphabeticalSortToggleState);

        setTimeout(() => {
            setAlphabeticalSort(!alphabeticalSort);
            setListVisible(true);
        }, 300);
    };

    // State to keep track of the selected draft
    const [selectedDraftId, setSelectedDraftId] = useState(initialDraftId);

    // Effect to set initial draft if not provided
    useEffect(() => {
        if (!selectedDraftId) {
            const initialDraft = Object.values(drafts).find(
                (draft) => draft.season_id === draftViewSeasonId
            );
            if (initialDraft) {
                setSelectedDraftId(initialDraft.id);
            }
        }
    }, [drafts, draftViewSeasonId, selectedDraftId]);

    // Handler for draft selection
    const handleSelectDraft = (draftId) => {
        dispatch(uiActions.showLoader());
        // Wait for the loader to show before changing the draft
        setTimeout(() => {
            setSelectedDraftId(draftId);
            const selectedDraft = drafts[draftId];
            draft_board_marker_mp_track(
                "Draft Selected",
                competitions[selectedDraft.competition_id],
                seasons[selectedDraft.season_id],
                { draft_id: draftId }
            );
            // Update the navigation URL, and create a new entry in the browser history
            navigate(`/app/draft/${draftId}/mark`);
            dispatch(uiActions.hideLoader());
        }, 100);
    };
    // Get the current draft
    const currentDraft = drafts[selectedDraftId];

    const playerboardId = currentDraft?.playerboard_id;
    const bgColor =
        currentDraft && currentDraft.background_color !== ""
            ? currentDraft.background_color
            : "#000000";
    const imgUrl =
        currentDraft && currentDraft.logo_url !== ""
            ? currentDraft.logo_url
            : "https://s3.amazonaws.com/slmediaimages/leagues/NHL.svg";
    const imgPosition =
        currentDraft && currentDraft.logo_position !== ""
            ? currentDraft.logo_position
            : "50% 50%";

    const board = useSelector(
        (state) => state.playerboards.playerboards[playerboardId]
    );

    useEffect(() => {
        currentDraft &&
            draft_board_marker_mp_track(
                "Draft Board Marker Tool Viewed",
                competitions[boardCompetitionId],
                seasons[currentDraft.season_id],
                { draft_id: selectedDraftId }
            );
    }, [
        boardCompetitionId,
        currentDraft,
        selectedDraftId,
        competitions,
        seasons,
    ]);

    const playerGroups =
        board &&
        chunkArray(
            // Sort players by name if alphabeticalSort is true
            alphabeticalSort
                ? Object.values([...board.players]).sort((a, b) => {
                      if (a.player.last_name < b.player.last_name) {
                          return -1;
                      }
                      if (a.player.last_name > b.player.last_name) {
                          return 1;
                      }
                      return 0;
                  })
                : board.players,
            32
        );

    const competition = useSelector(
        (state) => state.competitions.competitions[boardCompetitionId]
    );

    const players = useSelector((state) => state.players.players);

    const competition_activeteams = useSelector(
        (state) => state.competitions.activeTeams[boardCompetitionId]
    );

    const error = useSelector((state) => state.ui.notification);
    const fetchingRef = useRef(false);
    const updatingRef = useRef(false);
    const [reset, setReset] = useState(false);
    const dispatch = useDispatch();

    const [ws, setWs] = useState(null);

    // Create WebSocket connection.
    useEffect(() => {
        if (isAuthenticated) {
            const websocket = new WebSocket(
                process.env.REACT_APP_HABSHUB_WS_BASE_URL +
                    `playerboards/${playerboardId}/`
            );
            setWs(websocket);
        }
    }, [playerboardId, isAuthenticated]);

    // Connection opened, setup message receiving
    useEffect(() => {
        if (ws) {
            ws.onopen = () => {
                console.log(
                    `Playerboard ${playerboardId} WebSocket connected.`
                );
            };

            // Listen for messages
            ws.onmessage = (event) => {
                console.log(
                    `Playerboard ${playerboardId} WebSocket update received.`
                );
                updatingRef.current = true;
                dispatch(fetchPlayerboardData(playerboardId));
            };

            // Connection closed
            ws.onclose = () => {
                console.log(
                    `Playerboard ${playerboardId} WebSocket disconnected.`
                );
            };
        }

        // Close WebSocket connection when component unmounts
        return () => {
            if (ws) {
                ws.close();
            }
        };
    }, [ws, playerboardId, dispatch]);

    useEffect(() => {
        if (currentDraft && !board && !updatingRef.current) {
            dispatch(fetchPlayerboardData(playerboardId));
            fetchingRef.current = true;
            dispatch(uiActions.showLoader());
        }

        if (!competition) {
            dispatch(fetchCompetition(boardCompetitionId));
            fetchingRef.current = true;
            dispatch(uiActions.showLoader());
        }

        // The active teams will be fetched by the app component, but we
        // want to make sure that they are available before we render the board.
        // Otherwise, the team overlays will flicker as they are added to the board.
        if (!competition_activeteams) {
            dispatch(fetchCompetitionActiveTeams(boardCompetitionId));
            fetchingRef.current = true;
            dispatch(uiActions.showLoader());
        }

        if (
            board &&
            competition_activeteams &&
            (fetchingRef.current || updatingRef.current || reset)
        ) {
            setReset(false);
            fetchingRef.current = false;
            updatingRef.current = false;
            dispatch(uiActions.hideLoader());
        }
    }, [
        playerboardId,
        boardCompetitionId,
        competition,
        board,
        competition_activeteams,
        dispatch,
        currentDraft,
        reset,
    ]);

    const handleTeamChange = (e, player) => {
        const csrftoken = get_cookie("csrftoken");
        const teamId = e.target.value !== "none" ? e.target.value : null;
        const draftYear = teamId ? 2023 : null;

        fetch(
            process.env.REACT_APP_HABSHUB_API_BASE_URL +
                `hockey_analytics/players/${player.id}/`,
            {
                method: "PATCH",
                headers: {
                    "Content-Type": "application/json",
                    "X-CSRFToken": csrftoken,
                },
                credentials: "include", // Needed for cookies, credentials include,exclude,same-origin
                body: JSON.stringify({
                    nhl_drafted_team_id: teamId,
                    draft_year: draftYear,
                }),
            }
        )
            .then((response) => response.json())
            .then((data) => {
                // Here you handle the server response
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    const [mainContentWidth, setMainContentWidth] = useState(null);

    const onToggleTeamSelectionMode = useCallback(() => {
        navigate(`/app/draft/${selectedDraftId}`);
        //setTimeout(() => {
        //    setAlphabeticalSort(!alphabeticalSort);
        //    setListVisible(true);
        //}, 300);
    }, [selectedDraftId, navigate]);

    // If the user is not allowed to edit draft selections, or no draft is selected, render nothing
    if (!canEditDraftSelections || !currentDraft) return null;

    return (
        <AppPage>
            <CompressiblePanel
                onWidthChange={setMainContentWidth}
            >
                <OrgHeader
                    fullTitle={
                        (board ? board.name : "") + " - Draft Board Marker Tool"
                    }
                    mediumTitle={(board ? board.name : "") + " - Marker Tool"}
                    shortTitle={"Marker Tool"}
                    onOpen={onMenuOpen}
                    bgColor={bgColor}
                    imgUrl={imgUrl}
                    imgPosition={imgPosition}
                    parentWidth={mainContentWidth}
                />
                <MainContent>
                    <NavigationBar>
                        <DraftSelector
                            drafts={drafts}
                            selectedDraftId={selectedDraftId}
                            onSelectDraft={handleSelectDraft}
                        />
                        <NavigationSetting label="Team Selection Mode">
                            <Toggle
                                isChecked={true}
                                onToggle={onToggleTeamSelectionMode}
                            />
                        </NavigationSetting>
                        <NavigationSetting label="Sort by last name">
                            <Toggle
                                isChecked={alphabeticalSortToggleState}
                                onToggle={onToggleAlphabeticalSort}
                            />
                        </NavigationSetting>
                    </NavigationBar>
                    <MainBox>
                        <div
                            className={
                                classes.board_container +
                                (listVisible ? " " + classes.visible : "")
                            }
                        >
                            {(playerGroups && (
                                <div className={classes.group_container}>
                                    {playerGroups.map((group, index) => (
                                        <div
                                            key={index}
                                            className={classes.playergroup}
                                        >
                                            {group.map((board_player) => (
                                                <PlayerTeamSelector
                                                    key={board_player.player.id}
                                                    player={
                                                        players[
                                                            board_player.player
                                                                .id
                                                        ]
                                                    }
                                                    teams={[
                                                        ...competition_activeteams,
                                                    ].sort((a, b) =>
                                                        a.display_name.localeCompare(
                                                            b.display_name
                                                        )
                                                    )}
                                                    handleTeamChange={
                                                        handleTeamChange
                                                    }
                                                />
                                            ))}
                                        </div>
                                    ))}
                                </div>
                            )) ||
                                (error ? (
                                    <section>
                                        <div>{error.title}</div>
                                        <div>{error.message}</div>
                                    </section>
                                ) : (
                                    <div>Loading...</div>
                                ))}
                        </div>
                    </MainBox>
                </MainContent>
            </CompressiblePanel>
        </AppPage>
    );
};

export default DraftBoardMarker;
