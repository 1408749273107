import classes from "./OptionDropdown.module.css";
import { useEffect, useRef, useState, useCallback } from "react";

export const OptionDropdown = ({
    options,
    selectedOption,
    onSelect,
    OptionComponent,
    columnBreakpoints = [{ minWidth: 0, columns: 1 }],
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [columns, setColumns] = useState(1);
    const dropdownRef = useRef(null);
    const [calculatedTopPosition, setCalculatedTopPosition] = useState(0);

    // Ensure options is always an array
    const safeOptions = Array.isArray(options) ? options : [];

    // Calculate selectedOptionIndex more safely, excluding dividers from the calculation
    const selectedOptionIndex = selectedOption
        ? safeOptions
              .filter((option) => !option.isDivider)
              .findIndex((option) => option.id === selectedOption.id)
        : -1;

    // Determine if wide based on any option having a non-null label
    const isWide = safeOptions.some((option) => option.label != null);

    // Close dropdown when clicking outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                dropdownRef.current &&
                !dropdownRef.current.contains(event.target)
            ) {
                setIsOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () =>
            document.removeEventListener("mousedown", handleClickOutside);
    }, [dropdownRef]);

    useEffect(() => {
        const updateDropdownPosition = () => {
            if (!dropdownRef.current) return;

            const dropdownRect = dropdownRef.current.getBoundingClientRect();
            const viewportHeight = window.innerHeight;

            let newTopPosition = -(selectedOptionIndex * 32) + 1;
            // Check if dropdown goes above the viewport
            if (dropdownRect.top + newTopPosition < 0) {
                newTopPosition = 0;
            }
            // Check if dropdown goes below the viewport
            else if (dropdownRect.bottom + newTopPosition > viewportHeight) {
                newTopPosition = viewportHeight - dropdownRect.bottom;
            }

            // Set state with the new top position
            setCalculatedTopPosition(newTopPosition);
        };

        updateDropdownPosition();
    }, [selectedOptionIndex, options.length]);

    const columnBreakpointsRef = useRef(columnBreakpoints);

    // Update the ref when columnBreakpoints changes
    useEffect(() => {
        columnBreakpointsRef.current = columnBreakpoints;
    }, [columnBreakpoints]);

    const updateColumnCount = useCallback(() => {
        const windowWidth = window.innerWidth;
        const newColumns = columnBreakpointsRef.current
            .filter((bp) => windowWidth >= bp.minWidth)
            .reduce((acc, bp) => bp.columns, 1);
        setColumns(newColumns);
    }, []);

    useEffect(() => {
        updateColumnCount();
        window.addEventListener("resize", updateColumnCount);
        return () => window.removeEventListener("resize", updateColumnCount);
    }, [updateColumnCount]);

    const handleSelect = (option) => {
        // Only handle selection for non-divider options
        if (!option.isDivider) {
            onSelect(option);
            setIsOpen(false);
        }
    };

    const splitOptionsIntoColumns = (options, columnCount) => {
        const optionsPerColumn = Math.ceil(options.length / columnCount);
        return Array.from({ length: columnCount }, (_, index) =>
            options.slice(
                index * optionsPerColumn,
                (index + 1) * optionsPerColumn
            )
        );
    };

    const columnizedOptions = splitOptionsIntoColumns(safeOptions, columns);

    return (
        <div
            ref={dropdownRef}
            className={`${classes.dropdown_control}${
                isWide ? ` ${classes.wide}` : ""
            }`}
        >
            <div
                className={`${classes.option_selector}${
                    isWide ? ` ${classes.wide}` : ""
                }`}
            >
                <div
                    className={`${classes.dropdown_button}${
                        isWide ? ` ${classes.wide}` : ""
                    }`}
                    onClick={() => setIsOpen(!isOpen)}
                >
                    {selectedOption ? (
                        <OptionComponent optionValue={selectedOption} />
                    ) : (
                        <span>Select an option</span>
                    )}
                </div>
                {isOpen && (
                    <div
                        className={`${classes.dropdown_content} ${classes.multi_column}`}
                        style={{
                            top: `${calculatedTopPosition}px`,
                        }}
                    >
                        {columnizedOptions.map((column, columnIndex) => (
                            <div key={columnIndex} className={classes.column}>
                                {column.map((option) => (
                                    <div
                                        key={option.id}
                                        className={`${classes.dropdown_item}${
                                            selectedOption &&
                                            option.id === selectedOption.id
                                                ? ` ${classes.selected}`
                                                : ""
                                        }${
                                            option.isDivider
                                                ? ` ${classes.divider}`
                                                : ""
                                        }`}
                                        onClick={() => handleSelect(option)}
                                    >
                                        <OptionComponent optionValue={option} />
                                    </div>
                                ))}
                            </div>
                        ))}
                    </div>
                )}
            </div>
            <div
                className={`${classes.dropdown_button_triangle}${
                    isOpen ? ` ${classes.open}` : ""
                }`}
                onClick={() => setIsOpen(!isOpen)}
            >
                ▼
            </div>
        </div>
    );
};

export default OptionDropdown;
