import { configureStore } from "@reduxjs/toolkit";

import uiSlice from "./ui-slice";
import usersSlice from "./users-slice";
import competitionsSlice from "./competitions-slice";
import playersSlice from "./players-slice";
import playerboardsSlice from "./playerboards-slice";
import seasonsSlice from "./seasons-slice";
import teamsSlice from "./teams-slice";
import playerStatusCategoriesSlice from "./playerstatuscategories-slice";
import playerDevelopmentOptionsSlice from "./playerdevelopmentoptions-slice";
import surveysSlice from "./surveys-slice";

const store = configureStore({
    reducer: {
        ui: uiSlice.reducer,
        users: usersSlice.reducer,
        competitions: competitionsSlice.reducer,
        players: playersSlice.reducer,
        playerboards: playerboardsSlice.reducer,
        seasons: seasonsSlice.reducer,
        teams: teamsSlice.reducer,
        playerstatuscategories: playerStatusCategoriesSlice.reducer,
        playerdevelopmentoptions: playerDevelopmentOptionsSlice.reducer,
        surveys: surveysSlice.reducer,
    },
});

export default store;
