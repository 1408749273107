import { useEffect, useState, useMemo, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchCompetition } from "../store/competitions-actions";
import { fetchTeam, fetchTeamDatastash } from "../store/teams-actions";
import {
    fetchNHLTeamReserveListPlayers,
    fetchPlayer,
} from "../store/players-actions";
import { uiActions } from "../store/ui-slice";
import { longestWord } from "../utils";
import MainBox from "../layout/MainBox";
import PlayerGrid from "./player-grid/PlayerGrid";
import RadioButtonGroup from "../controls/buttons/RadioButtonGroup";
import OptionRadioBar from "../controls/OptionRadioBar";
import playertile_classes from "../components/playertile/Playertile.module.css";
import useAuth from "../hooks/use-auth";
import usePlayerDatastash from "../hooks/use-player-datastashes";

import classes from "./DepthChartView.module.css";
import { mp_track } from "../mixpanel";

const depth_chart_mp_track = (team, tileViewMode, event, properties = null) => {
    properties = {
        ...properties,
        team_id: team.id,
        team_name: team.display_name,
        tile_view_mode: tileViewMode,
    };

    mp_track(event, properties);
};

const depthChartTiers = [
    "NHL",
    "Off Roster",
    "AHL",
    "Qualified RFAs",
    "Junior, college and European",
];

const DepthChartView = ({
    teamId,
    metricsSeasonId = null,
    onPlayerClick,
    onContentLoading,
    altDatastash,
}) => {
    const { checkPermission } = useAuth();
    const canViewContractInformation = checkPermission(
        "core.can_view_contract_information"
    );

    const dispatch = useDispatch();
    const error = useSelector((state) => state.ui.notification);

    const currentSeasonId = useSelector(
        (state) => state.ui.systemConfiguration?.currentSeasonId
    );
    const seasonId = metricsSeasonId ? metricsSeasonId : currentSeasonId;

    const players = useSelector((state) => state.players.players);
    const players_datastashes = useSelector(
        (state) => state.players.datastashes
    );

    const teams = useSelector((state) => state.teams.teams);
    const team = teams && teams[teamId];
    const ahlTeamId = team && team.affiliations[0];

    const competitionId = 1;
    const competition = useSelector(
        (state) => state.competitions.competitions[competitionId]
    );

    const reduxPlayerTileMode = useSelector((state) => state.ui.playerTileMode);
    const [tileViewMode, setTileViewMode] = useState(reduxPlayerTileMode);

    const teamIdRef = useRef(null);

    useEffect(() => {
        setTileViewMode(reduxPlayerTileMode);
    }, [reduxPlayerTileMode]);

    useEffect(() => {
        if (team && teamIdRef.current !== team.id) {
            depth_chart_mp_track(team, tileViewMode, "View NHL Depth Chart");
            teamIdRef.current = team.id;
        }
    }, [team, tileViewMode]);

    const onTileViewModeChange = (mode) => {
        depth_chart_mp_track(
            team,
            tileViewMode,
            "Depth Chart Set Tile View Mode",
            {
                new_mode: mode,
            }
        );
        setTileViewMode(mode);
        dispatch(uiActions.setPlayerTileMode(mode));
    };

    const teams_datastashes = useSelector((state) => state.teams.datastashes);
    const dataStashName = altDatastash ? altDatastash : "depth_chart";

    const depth_chart = useMemo(() => {
        return (
            teams_datastashes &&
            teams_datastashes[teamId] &&
            teams_datastashes[teamId][dataStashName]
        );
    }, [teams_datastashes, dataStashName, teamId]);

    const injuryGamesMissed = useMemo(() => {
        return (
            teams_datastashes &&
            teams_datastashes[teamId] &&
            teams_datastashes[teamId].injury_games_missed
        );
    }, [teams_datastashes, teamId]);

    const currentSeasonInjuryGamesMissed = injuryGamesMissed?.find(
        (season) => season.season_id === seasonId
    ).games_missed;

    const ahl_team = ahlTeamId && teams && teams[ahlTeamId];

    const all_requiredPlayerIds = useMemo(() => {
        // Initialize an empty array to hold the IDs of all required players
        let allplayerIds = [];

        // Check if depth_chart exists, if not, return the empty array early
        if (!depth_chart) {
            return allplayerIds;
        }

        // Iterate over each tier in the depth_chart.position_groups
        Object.values(depth_chart.position_groups).forEach((tier) => {
            // Iterate over each position_group in the tier
            Object.values(tier).forEach((position_group) => {
                // Iterate over each player in the position_group
                Object.values(position_group).forEach((player) => {
                    // Add the player's ID to the allplayerIds array
                    allplayerIds.push(player.id);
                });
            });
        });

        // Return the array of missing player IDs
        return allplayerIds;
    }, [depth_chart]); // Depend on depth_chart and players to recompute when they change

    const missingPlayerIds = useMemo(() => {
        // Filter the all_requiredPlayerIds array to only include players that
        // are missing from the players store
        return all_requiredPlayerIds.filter((id) => !players[id]);
    }, [all_requiredPlayerIds, players]);

    const playersMissing = useMemo(
        () => all_requiredPlayerIds.length === 0 || missingPlayerIds.length > 0,
        [missingPlayerIds, all_requiredPlayerIds]
    );

    const teams_reserveListPlayerIds = useSelector(
        (state) => state.teams.reserveListPlayerIds[teamId]
    );

    const missingOutOfReserveListPlayerIds = useMemo(() => {
        const outOfReserveListIds =
            teams_reserveListPlayerIds &&
            missingPlayerIds &&
            missingPlayerIds.filter(
                (id) => !teams_reserveListPlayerIds.includes(id)
            );

        // Return the filtered array
        return outOfReserveListIds;
    }, [teams_reserveListPlayerIds, missingPlayerIds]);

    const { setRequiredPlayerIds, addRequiredDatastash, completedDatastashes } =
        usePlayerDatastash();

    // Create a ref to store the previous value of all_requiredPlayerIds
    const prevRequiredPlayerIdsRef = useRef();

    useEffect(() => {
        if (
            all_requiredPlayerIds.length > 0 &&
            JSON.stringify(all_requiredPlayerIds) !==
                JSON.stringify(prevRequiredPlayerIdsRef.current)
        ) {
            setRequiredPlayerIds(all_requiredPlayerIds);
            // Update the ref with the new value
            prevRequiredPlayerIdsRef.current = all_requiredPlayerIds;
        }
    }, [all_requiredPlayerIds, setRequiredPlayerIds]);

    useEffect(() => {
        addRequiredDatastash("career_performance_summary");
        addRequiredDatastash("scouting_reports_summaries");
    }, [addRequiredDatastash]);

    useEffect(() => {
        if (completedDatastashes?.career_performance_summary === false) {
            onContentLoading && onContentLoading(true);
        }
    }, [
        completedDatastashes.career_performance_summary,
        onContentLoading,
        dispatch,
    ]);

    useEffect(() => {
        if (completedDatastashes?.scouting_reports_summaries === false) {
            onContentLoading && onContentLoading(true);
        }
    }, [
        completedDatastashes.scouting_reports_summaries,
        onContentLoading,
        dispatch,
    ]);

    // Fetch required team datastashes
    useEffect(() => {
        if (!teams[teamId]) {
            dispatch(fetchTeam(teamId));
            onContentLoading && onContentLoading(true);
        }

        if (!competition) {
            dispatch(fetchCompetition(competitionId));
            onContentLoading && onContentLoading(true);
        }

        if (!depth_chart) {
            dispatch(fetchTeamDatastash(teamId, dataStashName));
            onContentLoading && onContentLoading(true);
        }

        if (!injuryGamesMissed) {
            dispatch(fetchTeamDatastash(teamId, "injury_games_missed"));
            onContentLoading && onContentLoading(true);
        }

        if (ahlTeamId && teams && !teams[ahlTeamId]) {
            dispatch(fetchTeam(ahlTeamId));
            onContentLoading && onContentLoading(true);
        }

        if (depth_chart && playersMissing) {
            // If any players from the depth chart are missing from the players
            // store, fetch the teams's reserve list
            dispatch(fetchNHLTeamReserveListPlayers(teamId, true));

            if (
                missingOutOfReserveListPlayerIds &&
                missingOutOfReserveListPlayerIds.length > 0
            ) {
                missingOutOfReserveListPlayerIds.forEach((playerId) => {
                    dispatch(fetchPlayer(playerId));
                });
            }
        }
    }, [
        teamId,
        teams,
        ahlTeamId,
        competition,
        depth_chart,
        injuryGamesMissed,
        playersMissing,
        missingOutOfReserveListPlayerIds,
        onContentLoading,
        dataStashName,
        dispatch,
    ]);

    useEffect(() => {
        if (
            team &&
            competition &&
            depth_chart &&
            injuryGamesMissed &&
            ahl_team &&
            !playersMissing &&
            completedDatastashes.career_performance_summary &&
            completedDatastashes.scouting_reports_summaries
        ) {
            onContentLoading && onContentLoading(false);
        }
    }, [
        team,
        competition,
        depth_chart,
        injuryGamesMissed,
        ahl_team,
        playersMissing,
        completedDatastashes.career_performance_summary,
        completedDatastashes.scouting_reports_summaries,
        onContentLoading,
        dispatch,
    ]);

    const { tieredRows, playerCompetitionMap } = useMemo(() => {
        if (!depth_chart) {
            return {
                tieredRows: null,
                playerCompetitionMap: null,
            };
        }

        // Create rows for each tier, in a tieredRows object mapped to the
        // tier.
        const rows = {};
        const playerCompetitionMap = {};
        depthChartTiers.forEach((tier) => {
            rows[tier] = [];
            let rowIndex = 0;

            // Filter out tryouts from position groups, if they are not
            // on an AHL contract
            const filteredPositionGroups = Object.fromEntries(
                Object.entries(depth_chart.position_groups[tier]).map(
                    ([position, groupPlayers]) => [
                        position,
                        groupPlayers.filter(
                            (player) =>
                                players &&
                                players[player.id] &&
                                (!players[player.id].is_tryout ||
                                    players[player.id].is_in_ahl)
                        ),
                    ]
                )
            );

            // Find the max length of the position groups in the tier
            const maxPositionGroupLength = Math.max(
                ...Object.values(filteredPositionGroups).map(
                    (position_group) => position_group.length
                )
            );

            // While any of the tier position groups values has a player at the current index, create a new row
            while (rowIndex < maxPositionGroupLength) {
                const row = {};
                for (
                    let index = 0;
                    index < Object.entries(filteredPositionGroups).length;
                    index++
                ) {
                    const [position, position_group] = Object.entries(
                        filteredPositionGroups
                    )[index];
                    if (position_group[rowIndex]) {
                        const player = { ...position_group[rowIndex] };
                        // Ignore tryouts
                        playerCompetitionMap[player.id] =
                            tier === "NHL" || tier === "Off Roster"
                                ? 1
                                : tier === "AHL"
                                ? 2
                                : player.cur_jce_team &&
                                  player.cur_jce_team.competition.id;
                        if (
                            !players[player.id].is_tryout ||
                            players[player.id].is_in_ahl
                        ) {
                            // Add the EP stats to the player object
                            player.eliteprospects_stats =
                                players_datastashes[
                                    player.id
                                ]?.career_performance_summary?.competitions
                                    .find(
                                        (comp) =>
                                            comp.id ===
                                            playerCompetitionMap[player.id]
                                    )
                                    ?.seasons.find(
                                        (season) =>
                                            season.season_id === seasonId
                                    )?.eliteprospects_stats || {};

                            // Map the skill ratings to the player object, transform it into a dictionary
                            player.skill_ratings =
                                players_datastashes[
                                    player.id
                                ]?.scouting_reports_summaries?.skill_ratings_report?.average_skill_ratings.reduce(
                                    (acc, rating) => {
                                        acc[rating.skill_name] =
                                            rating.average_rating;
                                        return acc;
                                    },
                                    {}
                                ) || {};

                            if (
                                tier !== "NHL" &&
                                tier !== "Off Roster" &&
                                tier !== "AHL"
                            ) {
                                player.nameDecoration =
                                    player.cur_jce_team &&
                                    player.cur_jce_team.competition
                                        .display_name;
                                player.nameDecorationClass =
                                    player.cur_jce_team &&
                                    player.cur_jce_team.incomplete_sample &&
                                    playertile_classes.light;
                            }
                            row[position] = player;
                        }
                    }
                }
                rows[tier].push(row);
                rowIndex++;
            }
        });

        return {
            tieredRows: rows,
            playerCompetitionMap: playerCompetitionMap,
        };
    }, [depth_chart, players, players_datastashes, seasonId]);

    const rosterCount = useMemo(() => {
        if (!depth_chart) {
            return null;
        }

        return Object.values(depth_chart.position_groups.NHL).reduce(
            (acc, position_group) => {
                return acc + position_group.length;
            },
            0
        );
    }, [depth_chart]);

    const contractCount = useMemo(() => {
        if (!depth_chart || playersMissing) {
            return null;
        }

        return Object.values(depth_chart.position_groups).reduce(
            (acc, tier) => {
                return (
                    acc +
                    Object.values(tier).reduce((acc, position_group) => {
                        return (
                            acc +
                            position_group.reduce((acc, player) => {
                                // The is_in_juniors flag in the reserve list
                                // is specifically there to indicate whether
                                // the player's contract counts in the maximum
                                // number of contracts.
                                return (
                                    acc +
                                    (players[player.id].is_signed &&
                                    players[player.id].contract_years >= 1 &&
                                    !players[player.id].is_in_juniors
                                        ? 1
                                        : 0)
                                );
                            }, 0)
                        );
                    }, 0)
                );
            },
            0
        );
    }, [depth_chart, players, playersMissing]);

    const handlePlayerClicked = (playerId) => {
        // We could eventually compute some peer data here
        const competitionId = playerCompetitionMap[playerId];
        onPlayerClick(playerId, competitionId);
    };

    return (
        <div className={classes.depth_chart_container}>
            {team && depth_chart && ahl_team && !playersMissing ? (
                depthChartTiers
                    .filter(
                        (tier) =>
                            tier in tieredRows && tieredRows[tier].length > 0
                    )
                    .map((tier) => {
                        return (
                            <MainBox key={tier} isSection={true}>
                                <div className={classes.tier_section}>
                                    <div
                                        className={classes.tier_section_header}
                                    >
                                        {tier !== "NHL" && tier !== "AHL" && (
                                            <>
                                                <div
                                                    className={
                                                        classes.tier_section_header_label
                                                    }
                                                >
                                                    {tier}
                                                </div>
                                                {tier === "Off Roster" && (
                                                    <div
                                                        className={
                                                            classes.count_container
                                                            + " " +
                                                            classes.man_games_lost_container
                                                        }
                                                    >
                                                        <div
                                                            className={
                                                                classes.count_label
                                                            }
                                                        >
                                                            MGL
                                                        </div>
                                                        <div
                                                            className={
                                                                classes.count
                                                            }
                                                        >
                                                            <span
                                                                className={
                                                                    classes.count_value
                                                                }
                                                            >
                                                                {currentSeasonInjuryGamesMissed?.regular}
                                                            </span>
                                                        </div>
                                                    </div>
                                                )}
                                            </>
                                        )}
                                        {tier === "NHL" && (
                                            <>
                                                <div
                                                    className={
                                                        classes.tier_logo
                                                    }
                                                >
                                                    <img
                                                        src={
                                                            competition.logo_url
                                                        }
                                                        alt={competition.name}
                                                        className={
                                                            classes.tier_logo
                                                        }
                                                    />
                                                </div>
                                                <div
                                                    className={
                                                        classes.count_container
                                                    }
                                                >
                                                    <div
                                                        className={
                                                            classes.count_label
                                                        }
                                                    >
                                                        Roster
                                                    </div>
                                                    <div
                                                        className={
                                                            classes.count
                                                        }
                                                    >
                                                        <span
                                                            className={
                                                                classes.count_value
                                                            }
                                                        >
                                                            {rosterCount}
                                                        </span>
                                                        <span
                                                            className={
                                                                classes.count_max
                                                            }
                                                        >
                                                            /23
                                                        </span>
                                                    </div>
                                                </div>
                                                {canViewContractInformation && (
                                                    <div
                                                        className={
                                                            classes.count_container
                                                        }
                                                    >
                                                        <div
                                                            className={
                                                                classes.count_label
                                                            }
                                                        >
                                                            Contracts
                                                        </div>
                                                        <div
                                                            className={
                                                                classes.count
                                                            }
                                                        >
                                                            <span
                                                                className={
                                                                    classes.count_value +
                                                                    (contractCount >=
                                                                    50
                                                                        ? " " +
                                                                          classes.count_warning
                                                                        : "")
                                                                }
                                                            >
                                                                {contractCount}
                                                            </span>
                                                            <span
                                                                className={
                                                                    classes.count_max
                                                                }
                                                            >
                                                                /50
                                                            </span>
                                                        </div>
                                                    </div>
                                                )}
                                                <div
                                                    className={classes.setting}
                                                >
                                                    <div
                                                        className={
                                                            classes.wide_selector
                                                        }
                                                    >
                                                        <RadioButtonGroup
                                                            options={[
                                                                {
                                                                    value: "trad_stats",
                                                                    label: "Trad Stats",
                                                                },
                                                                {
                                                                    value: "scouting",
                                                                    label: "Scouting",
                                                                },
                                                                {
                                                                    value: "performance",
                                                                    label: "Metrics",
                                                                },
                                                                // Conditionally add the "Cap" option if use has permission
                                                                ...(canViewContractInformation
                                                                    ? [
                                                                          {
                                                                              value: "cap",
                                                                              label: "Cap",
                                                                          },
                                                                      ]
                                                                    : []),
                                                            ]}
                                                            selectedValue={
                                                                tileViewMode
                                                            }
                                                            onValueChange={
                                                                onTileViewModeChange
                                                            }
                                                            direction="vertical"
                                                        />
                                                    </div>
                                                    <div
                                                        className={
                                                            classes.narrow_selector
                                                        }
                                                    >
                                                        <OptionRadioBar
                                                            options={[
                                                                {
                                                                    id: "trad_stats",
                                                                    full_label:
                                                                        "Trad Stats",
                                                                    short_label:
                                                                        "Stats",
                                                                },
                                                                {
                                                                    id: "scouting",
                                                                    full_label:
                                                                        "Scouting",
                                                                    short_label:
                                                                        "Scouting",
                                                                },
                                                                {
                                                                    id: "performance",
                                                                    full_label:
                                                                        "Metrics",
                                                                    short_label:
                                                                        "Metrics",
                                                                },
                                                                // Conditionally add the "Cap" option if use has permission
                                                                ...(canViewContractInformation
                                                                    ? [
                                                                          {
                                                                              id: "cap",
                                                                              full_label:
                                                                                  "Cap",
                                                                              short_label:
                                                                                  "Cap",
                                                                          },
                                                                      ]
                                                                    : []),
                                                            ]}
                                                            selectedOption={{
                                                                id: tileViewMode,
                                                            }}
                                                            onSelect={(
                                                                option
                                                            ) => {
                                                                onTileViewModeChange(
                                                                    option.id
                                                                );
                                                            }}
                                                            OptionComponent={({
                                                                optionValue,
                                                            }) => (
                                                                <div
                                                                    className={
                                                                        classes.page_class_option
                                                                    }
                                                                >
                                                                    <div
                                                                        className={
                                                                            classes.full_label
                                                                        }
                                                                    >
                                                                        {
                                                                            optionValue?.full_label
                                                                        }
                                                                    </div>
                                                                    <div
                                                                        className={
                                                                            classes.short_label
                                                                        }
                                                                    >
                                                                        {
                                                                            optionValue?.short_label
                                                                        }
                                                                    </div>
                                                                </div>
                                                            )}
                                                        />
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                        {tier === "AHL" && (
                                            <>
                                                <div
                                                    className={classes.ahl_logo}
                                                >
                                                    <img
                                                        src={ahl_team.logo_url}
                                                        alt={ahl_team.name}
                                                        className={
                                                            classes.tier_logo
                                                        }
                                                    />
                                                </div>
                                                <div
                                                    className={
                                                        classes.ahl_team_name +
                                                        (longestWord(
                                                            ahl_team.location +
                                                                " " +
                                                                ahl_team.name
                                                        ).length > 10
                                                            ? " " +
                                                              classes.ahl_team_name_small
                                                            : "")
                                                    }
                                                >
                                                    {ahl_team.location}{" "}
                                                    {ahl_team.name}
                                                </div>
                                            </>
                                        )}
                                    </div>
                                    <div
                                        className={classes.tier_section_content}
                                    >
                                        <PlayerGrid
                                            refId={teamId}
                                            grid_content={tieredRows[tier]}
                                            onTileClicked={handlePlayerClicked}
                                            tileView={tileViewMode}
                                            tightLayout={true}
                                            stackView={true}
                                            showProjectedFreeAgencyStatus={true}
                                        />
                                    </div>
                                </div>
                            </MainBox>
                        );
                    })
            ) : error ? (
                <section>
                    <div>{error.title}</div>
                    <div>{error.message}</div>
                </section>
            ) : (
                <div>Loading...</div>
            )}
        </div>
    );
};

export default DepthChartView;
