// File: habshub-dashboard-ui/src/components/TeamNewsView.js
// Change: Add onPlayerClick handler and mixpanel tracking

import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import PlayerNews from "./PlayerNews";
import { mp_track } from "../mixpanel";

import classes from "./TeamNewsView.module.css";

const team_news_mp_track = (team, event, properties = null) => {
    properties = {
        ...properties,
        team_id: team.id,
        team_name: team.display_name,
    };

    mp_track(event, properties);
};

const TeamNewsView = ({ teamId, onContentLoading, onPlayerClick }) => {
    const teams = useSelector((state) => state.teams.teams);
    const team = teams && teams[teamId];
    const players = useSelector((state) => state.players.players);

    // Track loading states separately
    const [isNewsLoading, setIsNewsLoading] = useState(false);
    const [isTeamLoading, setIsTeamLoading] = useState(!team);

    const teamIdRef = useRef(null);

    useEffect(() => {
        if (team && teamIdRef.current !== team.id) {
            team_news_mp_track(team, "View NHL Team News");
            teamIdRef.current = team.id;
        }
    }, [team]);

    // Update team loading state
    useEffect(() => {
        setIsTeamLoading(!team);
    }, [team]);

    // Coordinate overall loading state
    useEffect(() => {
        if (onContentLoading) {
            const isLoading = isNewsLoading || isTeamLoading;
            onContentLoading(isLoading);
        }
    }, [isNewsLoading, isTeamLoading, onContentLoading]);

    const handlePlayerClick = (playerId, competitionId) => {
        if (onPlayerClick) {
            const playerName =
                players[playerId]?.known_name || "Unknown Player";

            // Track the event in mixpanel
            team_news_mp_track(team, "Team News Player Click", {
                player_id: playerId,
                player_name: playerName,
                competition_id: competitionId,
            });

            onPlayerClick(playerId, competitionId);
        }
    };

    return (
        <div className={classes.team_news_container}>
            <div className={classes.team_news_content}>
                <PlayerNews
                    teamId={teamId}
                    onPlayerClick={handlePlayerClick}
                    isLoadingHandler={setIsNewsLoading}
                />
            </div>
        </div>
    );
};

export default TeamNewsView;
