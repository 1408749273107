import { uiActions } from "./ui-slice";
import { fetchData } from "../utils";
import { playerboardsActions } from "./playerboards-slice";
import { playersActions } from "./players-slice";
import { get_age_at_date, get_cookie } from "../utils";

const now = new Date(Date.now());

let ongoingRequests = {
    fetchPlayerboardData: {},
    syncTeamPlayerboard: {},
    fetchDrafts: null, // This will be either null or a Promise
    fetchDraftBoardsData: {},
    fetchDraftScoutPlayerboardData: {},
    fetchOwnedPlayerbords: null, // This will be either null or a Promise
    fetchTeamPlayerboards: {},
};

export const fetchPlayerboardData = (playerboardId) => {
    return async (dispatch) => {
        if (!ongoingRequests.fetchPlayerboardData[playerboardId]) {
            const fetchData = async () => {
                const response = await fetch(
                    process.env.REACT_APP_HABSHUB_API_BASE_URL +
                        "hockey_analytics/playerboards/" +
                        playerboardId +
                        "/?include_datastash=career_performance_summary&include_datastash=scouting_reports_summaries"
                );

                if (!response.ok) {
                    throw new Error("Could not fetch player board data!");
                }

                const data = await response.json();

                return data;
            };

            ongoingRequests.fetchPlayerboardData[playerboardId] = fetchData()
                .then((playerboardData) => {
                    // map over players to extract and calculate needed properties
                    const players = playerboardData.players.map(
                        (board_player) => {
                            const player_dob = new Date(
                                Date.parse(
                                    board_player.player.birth_date + "T00:00:00"
                                )
                            );
                            board_player.player.age = get_age_at_date(
                                player_dob,
                                now
                            );
                            return board_player.player;
                        }
                    );

                    // Dispatch updatePlayers action with mapped players
                    dispatch(playersActions.updatePlayers(players));

                    // map over players to extract and calculate needed properties
                    const player_datastashes = playerboardData.players.reduce(
                        (acc, board_player) => {
                            Object.keys(acc).forEach((key) => {
                                if (board_player[key]) {
                                    acc[key].push({
                                        player_id: board_player.player.id,
                                        data: board_player[key],
                                    });
                                }
                            });
                            return acc;
                        },
                        {
                            career_performance_summary: [],
                            scouting_reports_summaries: [],
                        }
                    );

                    // Dispatch updatePlayersDatastash action with fetched datastashes
                    Object.keys(player_datastashes).forEach((key) => {
                        dispatch(
                            playersActions.updatePlayersDatastash({
                                datastashName: key,
                                data: player_datastashes[key],
                            })
                        );
                    });

                    dispatch(
                        playerboardsActions.updatePlayerboard(playerboardData)
                    );
                    delete ongoingRequests.fetchPlayerboardData[playerboardId];
                    return playerboardData;
                })
                .catch((error) => {
                    dispatch(
                        uiActions.showNotification({
                            status: "error",
                            title: "Error!",
                            message: "Fetching player board data failed!",
                        })
                    );
                    delete ongoingRequests.fetchPlayerboardData[playerboardId];
                    throw error;
                });
        }

        return ongoingRequests.fetchPlayerboardData[playerboardId];
    };
};

export const syncTeamPlayerboard = (playerboardId, forceReset, onError) => {
    const csrftoken = get_cookie("csrftoken");
    return async (dispatch) => {
        if (!ongoingRequests.syncTeamPlayerboard[playerboardId]) {
            const fetchData = async () => {
                const response = await fetch(
                    process.env.REACT_APP_HABSHUB_API_BASE_URL +
                        "hockey_analytics/playerboards/" +
                        playerboardId +
                        "/syncdepthchartplayers/" +
                        (forceReset ? "?force_reset=true" : ""),
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            "X-CSRFToken": csrftoken,
                        },
                        credentials: "include", // Needed for cookies, credentials include,exclude,same-origin
                    }
                );

                if (!response.ok) {
                    throw new Error("Could not sync team player board!");
                }

                const data = await response.json();
                return data;
            };

            ongoingRequests.syncTeamPlayerboard[playerboardId] = fetchData()
                .then((syncPlayerboardData) => {
                    if (syncPlayerboardData.has_changed) {
                        dispatch(
                            playerboardsActions.updatePlayerboard(
                                syncPlayerboardData.playerboard
                            )
                        );
                    }

                    delete ongoingRequests.syncTeamPlayerboard[playerboardId];
                    return syncPlayerboardData;
                })
                .catch((error) => {
                    dispatch(
                        uiActions.showNotification({
                            status: "error",
                            title: "Error!",
                            message: "Syncing team player board failed!",
                        })
                    );
                    delete ongoingRequests.syncTeamPlayerboard[playerboardId];
                    throw error;
                });
        }

        return ongoingRequests.syncTeamPlayerboard[playerboardId];
    };
};

export const movePlayer = (
    playerboardId,
    playerId,
    newRow,
    newPosition,
    requestId,
    onError
) => {
    const csrftoken = get_cookie("csrftoken");
    return async (dispatch) => {
        const fetchData = async () => {
            const response = await fetch(
                process.env.REACT_APP_HABSHUB_API_BASE_URL +
                    "hockey_analytics/playerboards/" +
                    playerboardId +
                    "/players/" +
                    playerId +
                    "/move/",
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "X-CSRFToken": csrftoken,
                    },
                    credentials: "include", // Needed for cookies, credentials include,exclude,same-origin
                    body: JSON.stringify({
                        row: newRow,
                        position: newPosition,
                        request_id: requestId,
                    }),
                }
            );

            if (!response.ok) {
                throw new Error("Could not move player on player board!");
            }

            const data = await response.json();

            return data;
        };

        try {
            const playerboardData = await fetchData();

            // Need to recompute player ages
            playerboardData.players.forEach((board_player) => {
                const player_dob = new Date(
                    Date.parse(board_player.player.birth_date + "T00:00:00")
                );
                board_player.player.age = get_age_at_date(player_dob, now);
            });

            dispatch(playerboardsActions.updatePlayerboard(playerboardData));
        } catch (error) {
            dispatch(
                uiActions.showNotification({
                    status: "error",
                    title: "Error!",
                    message: "Moving player on player board failed!",
                })
            );
            onError && onError(error);
        }
    };
};

export const insertRow = (
    playerboardId,
    newRowIndex,
    primaryPosition = null,
    requestId,
    onError
) => {
    const csrftoken = get_cookie("csrftoken");
    return async (dispatch) => {
        const fetchData = async () => {
            const response = await fetch(
                process.env.REACT_APP_HABSHUB_API_BASE_URL +
                    "hockey_analytics/playerboards/" +
                    playerboardId +
                    "/rows/insert/" +
                    (primaryPosition
                        ? "?primary_position=" + primaryPosition
                        : ""),
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "X-CSRFToken": csrftoken,
                    },
                    credentials: "include", // Needed for cookies, credentials include,exclude,same-origin
                    body: JSON.stringify({
                        rows: [newRowIndex],
                        request_id: requestId,
                    }),
                }
            );

            if (!response.ok) {
                throw new Error("Could not insert row on player board!");
            }

            const data = await response.json();

            return data;
        };

        try {
            const playerboardData = await fetchData();

            // Need to recompute player ages
            playerboardData.players.forEach((board_player) => {
                const player_dob = new Date(
                    Date.parse(board_player.player.birth_date + "T00:00:00")
                );
                board_player.player.age = get_age_at_date(player_dob, now);
            });

            dispatch(playerboardsActions.updatePlayerboard(playerboardData));
        } catch (error) {
            dispatch(
                uiActions.showNotification({
                    status: "error",
                    title: "Error!",
                    message: "Insert row on player board failed!",
                })
            );
            onError && onError(error);
        }
    };
};

export const removeRow = (
    playerboardId,
    removeRowIndex,
    primaryPosition = null,
    requestId,
    onError
) => {
    const csrftoken = get_cookie("csrftoken");
    return async (dispatch) => {
        const fetchData = async () => {
            const response = await fetch(
                process.env.REACT_APP_HABSHUB_API_BASE_URL +
                    "hockey_analytics/playerboards/" +
                    playerboardId +
                    "/rows/remove/" +
                    (primaryPosition
                        ? "?primary_position=" + primaryPosition
                        : ""),
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "X-CSRFToken": csrftoken,
                    },
                    credentials: "include", // Needed for cookies, credentials include,exclude,same-origin
                    body: JSON.stringify({
                        rows: [removeRowIndex],
                        request_id: requestId,
                    }),
                }
            );

            if (!response.ok) {
                throw new Error("Could not remove row on player board!");
            }

            const data = await response.json();

            return data;
        };

        try {
            const playerboardData = await fetchData();

            // Need to recompute player ages
            playerboardData.players.forEach((board_player) => {
                const player_dob = new Date(
                    Date.parse(board_player.player.birth_date + "T00:00:00")
                );
                board_player.player.age = get_age_at_date(player_dob, now);
            });

            dispatch(playerboardsActions.updatePlayerboard(playerboardData));
        } catch (error) {
            dispatch(
                uiActions.showNotification({
                    status: "error",
                    title: "Error!",
                    message: "Remove row on player board failed!",
                })
            );
            onError && onError(error);
        }
    };
};

// Fetches all drafts from the API
export const fetchDrafts = () => {
    return async (dispatch) => {
        if (!ongoingRequests.fetchDrafts) {
            ongoingRequests.fetchDrafts = fetchData(
                process.env.REACT_APP_HABSHUB_API_BASE_URL +
                    "hockey_analytics/drafts/",
                "Could not fetch drafts data!"
            )
                .then((draftsData) => {
                    dispatch(playerboardsActions.updateDrafts(draftsData));
                    ongoingRequests.fetchDrafts = null;
                    return draftsData;
                })
                .catch((error) => {
                    dispatch(
                        uiActions.showNotification({
                            status: "error",
                            title: "Error!",
                            message: "Fetching all drafts data failed!",
                        })
                    );
                    ongoingRequests.fetchDrafts = null;
                    throw error;
                });
        }

        return ongoingRequests.fetchDrafts;
    };
};


// Fetches all draft boards for a given draft
export const fetchDraftBoards = (draftId) => {
    return async (dispatch) => {
        if (!ongoingRequests.fetchDraftBoardsData[draftId]) {
            ongoingRequests.fetchDraftBoardsData[draftId] = fetchData(
                process.env.REACT_APP_HABSHUB_API_BASE_URL +
                    "hockey_analytics/draftboards/?draft_id=" +
                    draftId,
                `Could not fetch Draft Boards data for draft ${draftId}!`
            )
                .then((draftBoardsData) => {
                    dispatch(
                        playerboardsActions.updateDraftBoards(
                            draftBoardsData
                        )
                    );
                    ongoingRequests.fetchDraftBoardsData[draftId] =
                        null;
                    return draftBoardsData;
                })
                .catch((error) => {
                    dispatch(
                        uiActions.showNotification({
                            status: "error",
                            title: "Error!",
                            message: `Fetching draft ${draftId} Draft Boards data failed!`,
                        })
                    );
                    ongoingRequests.fetchDraftBoardsData[draftId] =
                        null;
                    throw error;
                });
        }

        return ongoingRequests.fetchDraftBoardsData[draftId];
    };
};

// Fetches all scout playerboards for a given draft
export const fetchDraftScoutPlayerboards = (draftId) => {
    return async (dispatch) => {
        if (!ongoingRequests.fetchDraftScoutPlayerboardData[draftId]) {
            ongoingRequests.fetchDraftScoutPlayerboardData[draftId] = fetchData(
                process.env.REACT_APP_HABSHUB_API_BASE_URL +
                    "hockey_analytics/scoutdraftboards/?draft_id=" +
                    draftId,
                `Could not fetch Scout Playerboards data for draft ${draftId}!`
            )
                .then((draftScoutPlayerboardsData) => {
                    dispatch(
                        playerboardsActions.updateScoutPlayerboards(
                            draftScoutPlayerboardsData
                        )
                    );
                    ongoingRequests.fetchDraftScoutPlayerboardData[draftId] =
                        null;
                    return draftScoutPlayerboardsData;
                })
                .catch((error) => {
                    dispatch(
                        uiActions.showNotification({
                            status: "error",
                            title: "Error!",
                            message: `Fetching draft ${draftId} Scout Playerboards data failed!`,
                        })
                    );
                    ongoingRequests.fetchDraftScoutPlayerboardData[draftId] =
                        null;
                    throw error;
                });
        }

        return ongoingRequests.fetchDraftScoutPlayerboardData[draftId];
    };
};

// Fetches all owned Playerboards from the API
export const fetchOwnedPlayerboards = () => {
    return async (dispatch) => {
        if (!ongoingRequests.fetchOwnedPlayerbords) {
            ongoingRequests.fetchOwnedPlayerbords = fetchData(
                process.env.REACT_APP_HABSHUB_API_BASE_URL +
                    "hockey_analytics/playerboards/?owned=true",
                "Could not fetch owned Playerboards data!"
            )
                .then((ownedPlayerboardsData) => {
                    dispatch(
                        playerboardsActions.updateOwnedPlayerboards(
                            ownedPlayerboardsData
                        )
                    );
                    ongoingRequests.fetchOwnedPlayerbords = null;
                    return ownedPlayerboardsData;
                })
                .catch((error) => {
                    dispatch(
                        uiActions.showNotification({
                            status: "error",
                            title: "Error!",
                            message:
                                "Fetching all owned Playerboards data failed!",
                        })
                    );
                    ongoingRequests.fetchOwnedPlayerbords = null;
                    throw error;
                });
        }

        return ongoingRequests.fetchOwnedPlayerbords;
    };
};

export const getOrCreateOwnedTeamPlayerboard = (name, teamId, onError) => {
    const csrftoken = get_cookie("csrftoken");
    return async (dispatch) => {
        const fetchData = async () => {
            const response = await fetch(
                process.env.REACT_APP_HABSHUB_API_BASE_URL +
                    "hockey_analytics/playerboards/get-or-create/",
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "X-CSRFToken": csrftoken,
                    },
                    credentials: "include", // Needed for cookies, credentials include,exclude,same-origin
                    body: JSON.stringify({
                        name: name,
                        team_id: teamId,
                    }),
                }
            );

            if (!response.ok) {
                throw new Error("Could not create player board!");
            }

            const data = await response.json();

            return data;
        };

        try {
            const playerboardData = await fetchData();
            dispatch(
                playerboardsActions.updateOwnedPlayerboard(playerboardData)
            );
        } catch (error) {
            dispatch(
                uiActions.showNotification({
                    status: "error",
                    title: "Error!",
                    message: "Create player board failed!",
                })
            );
            onError && onError(error);
        }
    };
};

export const createOwnedPlayerboard = (name, teamId, onError) => {
    const csrftoken = get_cookie("csrftoken");
    return async (dispatch) => {
        const fetchData = async () => {
            const response = await fetch(
                process.env.REACT_APP_HABSHUB_API_BASE_URL +
                    "hockey_analytics/playerboards/",
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "X-CSRFToken": csrftoken,
                    },
                    credentials: "include", // Needed for cookies, credentials include,exclude,same-origin
                    body: JSON.stringify({
                        name: name,
                        team_id: teamId,
                    }),
                }
            );

            if (!response.ok) {
                throw new Error("Could not create player board!");
            }

            const data = await response.json();

            return data;
        };

        try {
            const playerboardData = await fetchData();
            dispatch(
                playerboardsActions.updateOwnedPlayerboard(playerboardData)
            );
        } catch (error) {
            dispatch(
                uiActions.showNotification({
                    status: "error",
                    title: "Error!",
                    message: "Create player board failed!",
                })
            );
            onError && onError(error);
        }
    };
};

// Fetches Playerboards linked to a team from the API
export const fetchTeamPlayerboards = (teamId) => {
    return async (dispatch) => {
        if (!ongoingRequests.fetchTeamPlayerboards[teamId]) {
            ongoingRequests.fetchTeamPlayerboards[teamId] = fetchData(
                process.env.REACT_APP_HABSHUB_API_BASE_URL +
                    "hockey_analytics/playerboards/?team_id=" +
                    teamId,
                "Could not fetch team Playerboards data!"
            )
                .then((teamPlayerboardsData) => {
                    ongoingRequests.fetchTeamPlayerboards[teamId] = null;
                    return teamPlayerboardsData;
                })
                .catch((error) => {
                    dispatch(
                        uiActions.showNotification({
                            status: "error",
                            title: "Error!",
                            message:
                                "Fetching all team Playerboards data failed!",
                        })
                    );
                    ongoingRequests.fetchTeamPlayerboards[teamId] = null;
                    throw error;
                });
        }

        return ongoingRequests.fetchTeamPlayerboards[teamId];
    };
};
