import { createSlice } from "@reduxjs/toolkit";
import { get_age_at_date } from "../utils";

const now = new Date(Date.now());

const playersSlice = createSlice({
    name: "players",
    initialState: {
        players: {},
        playeragencies: {},
        playeragents: {},
        datastashes: {},
        seasondatastashes: {},
        completeseasondatastashes: {},
        rinknet_reports: {},
        injuries: {},
        staffnotes: {},
        statusupdates: {},
        interactions: {},
        developmentpriorities: {},
        developmentsessions: {},
        developmentnotes: {},
        contracts: {},
        playernews: {},
    },
    reducers: {
        updatePlayer(state, action) {
            const player = action.payload;
            const player_dob = new Date(
                Date.parse(player.birth_date + "T00:00:00")
            );
            player.age = get_age_at_date(player_dob, now);
            state.players[player.id] = player;
        },
        updatePlayerAgencies(state, action) {
            action.payload.forEach((playerAgency) => {
                state.playeragencies[playerAgency.id] = playerAgency;
            });
        },
        updatePlayerAgents(state, action) {
            action.payload.forEach((playerAgent) => {
                state.playeragents[playerAgent.id] = playerAgent;
            });
        },
        updatePlayers(state, action) {
            action.payload.forEach((player) => {
                const player_dob = new Date(
                    Date.parse(player.birth_date + "T00:00:00")
                );
                player.age = get_age_at_date(player_dob, now);
                state.players[player.id] = player;
            });
        },
        updatePlayerDatastash(state, action) {
            const { playerId, datastashName, data } = action.payload;
            if (!state.datastashes[playerId]) {
                state.datastashes[playerId] = {};
            }
            state.datastashes[playerId][datastashName] = data;
        },
        updatePlayersDatastash(state, action) {
            const { datastashName, data } = action.payload;

            data.forEach((datastash) => {
                const { player_id: playerId, data } = datastash;
                if (!state.datastashes[playerId]) {
                    state.datastashes[playerId] = {};
                }
                state.datastashes[playerId][datastashName] = data;
            });
        },
        updatePlayerSeasonDatastash(state, action) {
            const { playerId, datastashName, seasonId, data } = action.payload;
            if (!state.seasondatastashes[playerId]) {
                state.seasondatastashes[playerId] = {};
            }
            if (!state.seasondatastashes[playerId][datastashName]) {
                state.seasondatastashes[playerId][datastashName] = {};
            }
            state.seasondatastashes[playerId][datastashName][seasonId] = data;
        },
        updatePlayerSeasonsDatastash(state, action) {
            const { playerId, datastashName, data } = action.payload;

            data.forEach((datastash) => {
                const {
                    player_id: playerId,
                    season_id: seasonId,
                    data,
                } = datastash;
                if (!state.seasondatastashes[playerId]) {
                    state.seasondatastashes[playerId] = {};
                }
                if (!state.seasondatastashes[playerId][datastashName]) {
                    state.seasondatastashes[playerId][datastashName] = {};
                }
                state.seasondatastashes[playerId][datastashName][seasonId] =
                    data;
            });

            if (!state.completeseasondatastashes[playerId]) {
                state.completeseasondatastashes[playerId] = {};
            }
            state.completeseasondatastashes[playerId][datastashName] = true;
        },
        updatePlayersSeasonsDatastash(state, action) {
            const { playerIds, seasonIds, datastashName, data } =
                action.payload;

            const emptyDatastashes = playerIds.reduce((acc, playerId) => {
                acc[playerId] = seasonIds.reduce((acc, seasonId) => {
                    acc[seasonId] = true;
                    return acc;
                }, {});
                return acc;
            }, {});

            data.forEach((datastash) => {
                const {
                    player_id: playerId,
                    season_id: seasonId,
                    data,
                } = datastash;
                if (!state.seasondatastashes[playerId]) {
                    state.seasondatastashes[playerId] = {};
                }
                if (!state.seasondatastashes[playerId][datastashName]) {
                    state.seasondatastashes[playerId][datastashName] = {};
                }
                state.seasondatastashes[playerId][datastashName][seasonId] =
                    data;
                emptyDatastashes[playerId][seasonId] = false;
            });

            Object.entries(emptyDatastashes).forEach(([playerId, seasons]) => {
                if (!state.seasondatastashes[playerId]) {
                    state.seasondatastashes[playerId] = {};
                }
                if (!state.seasondatastashes[playerId][datastashName]) {
                    state.seasondatastashes[playerId][datastashName] = {};
                }
                // Set the value to empty array for all seasons that are still true
                // (i.e. no data was found for that season)
                Object.entries(seasons).forEach(([seasonId, value]) => {
                    if (value) {
                        state.seasondatastashes[playerId][datastashName][
                            seasonId
                        ] = [];
                    }
                });
            });
        },
        updatePlayerRinknetReports(state, action) {
            const { player_id, game_reports } = action.payload;
            state.rinknet_reports[player_id] = game_reports;
        },
        updatePlayerInjuries(state, action) {
            const injuries = action.payload;

            // Map injuries by player_id
            const injuries_by_player_id = injuries.reduce((acc, injury) => {
                const player_id = injury.player_id;
                if (!acc[player_id]) {
                    acc[player_id] = [];
                }
                acc[player_id].push(injury);
                return acc;
            }, {});

            // Update the state with the new injuries
            Object.entries(injuries_by_player_id).forEach(
                ([player_id, injuries]) => {
                    // If there are no injuries yet for this player, initialize the array
                    if (!state.injuries[player_id]) {
                        state.injuries[player_id] = [];
                    }
                    // Add the new injuries to the existing injuries, replacing any existing injuries with the same id
                    state.injuries[player_id] = [
                        ...state.injuries[player_id].filter(
                            (injury) =>
                                !injuries.map((i) => i.id).includes(injury.id)
                        ),
                        ...injuries,
                    ];
                    // Sort the injuries by reversed start date, then created_at, if start_date is not available
                    // The first injury in the array is the most recent injury
                    state.injuries[player_id].sort((a, b) => {
                        const a_date = a.start_date || a.created_at;
                        const b_date = b.start_date || b.created_at;
                        return b_date.localeCompare(a_date);
                    });
                }
            );
        },
        updatePlayerStaffNotes(state, action) {
            const staff_notes = action.payload;

            // Map staff notes by player_id
            const staff_notes_by_player_id = staff_notes.reduce(
                (acc, staff_note) => {
                    const player_id = staff_note.player_id;
                    if (!acc[player_id]) {
                        acc[player_id] = [];
                    }
                    acc[player_id].push(staff_note);
                    return acc;
                },
                {}
            );

            // Update the state with the new staff notes
            Object.entries(staff_notes_by_player_id).forEach(
                ([player_id, staff_notes]) => {
                    // If there are no staff notes yet for this player, initialize the array
                    if (!state.staffnotes[player_id]) {
                        state.staffnotes[player_id] = [];
                    }
                    // Add the new staff notes to the existing staff notes, replacing any existing staff notes with the same id
                    state.staffnotes[player_id] = [
                        ...state.staffnotes[player_id].filter(
                            (staff_note) =>
                                !staff_notes
                                    .map((sn) => sn.id)
                                    .includes(staff_note.id)
                        ),
                        ...staff_notes,
                    ];
                    // Sort the staff notes by reversed created_at
                    // The first staff note in the array is the most recent staff note
                    state.staffnotes[player_id].sort((a, b) =>
                        b.created_at.localeCompare(a.created_at)
                    );
                }
            );
        },
        updatePlayerStatusUpdates(state, action) {
            const status_updates = action.payload;

            // Map status updates by player_id
            const status_updates_by_player_id = status_updates.reduce(
                (acc, status_update) => {
                    const player_id = status_update.player_id;
                    if (!acc[player_id]) {
                        acc[player_id] = [];
                    }
                    acc[player_id].push(status_update);
                    return acc;
                },
                {}
            );

            // Update the state with the new status updates
            Object.entries(status_updates_by_player_id).forEach(
                ([player_id, status_updates]) => {
                    // If there are no status updates yet for this player, initialize the array
                    if (!state.statusupdates[player_id]) {
                        state.statusupdates[player_id] = [];
                    }
                    // Update existing status updates with modified properties and add new status updates not already present
                    state.statusupdates[player_id] = [
                        ...state.statusupdates[player_id].map(
                            (existingUpdate) => {
                                const newUpdate = status_updates.find(
                                    (update) => update.id === existingUpdate.id
                                );
                                return newUpdate
                                    ? { ...existingUpdate, ...newUpdate }
                                    : existingUpdate;
                            }
                        ),
                        ...status_updates.filter(
                            (newUpdate) =>
                                !state.statusupdates[player_id].some(
                                    (existingUpdate) =>
                                        existingUpdate.id === newUpdate.id
                                )
                        ),
                    ];
                    // Sort the status updates by reversed created_at
                    // The first status update in the array is the most recent status update
                    state.statusupdates[player_id].sort((a, b) =>
                        b.created_at.localeCompare(a.created_at)
                    );
                }
            );
        },
        updatePlayerDevelopmentPriorities(state, action) {
            const development_priorities = action.payload;

            // Map development priorities by player_id
            const development_priorities_by_player_id =
                development_priorities.reduce((acc, development_priority) => {
                    const player_id = development_priority.player_id;
                    if (!acc[player_id]) {
                        acc[player_id] = [];
                    }
                    acc[player_id].push(development_priority);
                    return acc;
                }, {});

            // Update the state with the new development priorities
            Object.entries(development_priorities_by_player_id).forEach(
                ([player_id, development_priorities]) => {
                    // If there are no development priorities yet for this player, initialize the array
                    if (!state.developmentpriorities[player_id]) {
                        state.developmentpriorities[player_id] = [];
                    }
                    // Update existing development priorities with modified properties and add new development priorities not already present
                    state.developmentpriorities[player_id] = [
                        ...state.developmentpriorities[player_id].map(
                            (existingDevelopmentPriority) => {
                                const newDevelopmentPriority =
                                    development_priorities.find(
                                        (developmentPriority) =>
                                            developmentPriority.id ===
                                            existingDevelopmentPriority.id
                                    );
                                return newDevelopmentPriority
                                    ? {
                                          ...existingDevelopmentPriority,
                                          ...newDevelopmentPriority,
                                      }
                                    : existingDevelopmentPriority;
                            }
                        ),
                        ...development_priorities.filter(
                            (newDevelopmentPriority) =>
                                !state.developmentpriorities[player_id].some(
                                    (existingDevelopmentPriority) =>
                                        existingDevelopmentPriority.id ===
                                        newDevelopmentPriority.id
                                )
                        ),
                    ];
                    // Sort the development priorities by reversed created_at
                    // The first development priority in the array is the most recent development priority
                    state.developmentpriorities[player_id].sort((a, b) =>
                        b.created_at.localeCompare(a.created_at)
                    );
                }
            );
        },
        updatePlayerInteractions(state, action) {
            const interactions = action.payload;

            // Map interactions by player_id
            const interactions_by_player_id = interactions.reduce(
                (acc, interaction) => {
                    const player_id = interaction.player_id;
                    if (!acc[player_id]) {
                        acc[player_id] = [];
                    }
                    acc[player_id].push(interaction);
                    return acc;
                },
                {}
            );

            // Update the state with the new interactions
            Object.entries(interactions_by_player_id).forEach(
                ([player_id, interactions]) => {
                    // If there are no interactions yet for this player, initialize the array
                    if (!state.interactions[player_id]) {
                        state.interactions[player_id] = [];
                    }
                    // Update existing interactions with modified properties and add new interactions not already present
                    state.interactions[player_id] = [
                        ...state.interactions[player_id].map(
                            (existingInteraction) => {
                                const newSession = interactions.find(
                                    (session) =>
                                        session.id === existingInteraction.id
                                );
                                return newSession
                                    ? { ...existingInteraction, ...newSession }
                                    : existingInteraction;
                            }
                        ),
                        ...interactions.filter(
                            (newInteraction) =>
                                !state.interactions[player_id].some(
                                    (existingInteraction) =>
                                        existingInteraction.id === newInteraction.id
                                )
                        ),
                    ];
                    // Sort the interactions by reversed date. If two
                    // sessions have the same date, sort by created_at
                    // The first interaction in the array is the most recent interaction
                    state.interactions[player_id].sort((a, b) => {
                        if (a.date !== b.date) {
                            return b.date.localeCompare(a.date);
                        } else {
                            return b.created_at.localeCompare(a.created_at);
                        }
                    });
                }
            );
        },
        updatePlayerDevelopmentSessions(state, action) {
            const development_sessions = action.payload;

            // Map development sessions by player_id
            const development_sessions_by_player_id =
                development_sessions.reduce((acc, development_session) => {
                    const player_id = development_session.player_id;
                    if (!acc[player_id]) {
                        acc[player_id] = [];
                    }
                    acc[player_id].push(development_session);
                    return acc;
                }, {});

            // Update the state with the new development sessions
            Object.entries(development_sessions_by_player_id).forEach(
                ([player_id, development_sessions]) => {
                    // If there are no development sessions yet for this player, initialize the array
                    if (!state.developmentsessions[player_id]) {
                        state.developmentsessions[player_id] = [];
                    }
                    // Update existing development sessions with modified properties and add new development sessions not already present
                    state.developmentsessions[player_id] = [
                        ...state.developmentsessions[player_id].map(
                            (existingSession) => {
                                const newSession = development_sessions.find(
                                    (session) =>
                                        session.id === existingSession.id
                                );
                                return newSession
                                    ? { ...existingSession, ...newSession }
                                    : existingSession;
                            }
                        ),
                        ...development_sessions.filter(
                            (newSession) =>
                                !state.developmentsessions[player_id].some(
                                    (existingSession) =>
                                        existingSession.id === newSession.id
                                )
                        ),
                    ];
                    // Sort the development sessions by reversed date. If two
                    // sessions have the same date, sort by created_at
                    // The first development session in the array is the most recent development session
                    state.developmentsessions[player_id].sort((a, b) => {
                        if (a.date !== b.date) {
                            return b.date.localeCompare(a.date);
                        } else {
                            return b.created_at.localeCompare(a.created_at);
                        }
                    });
                }
            );
        },
        updatePlayerDevelopmentNotes(state, action) {
            const development_notes = action.payload;

            // Map development notes by player_id
            const development_notes_by_player_id = development_notes.reduce(
                (acc, development_note) => {
                    const player_id = development_note.player_id;
                    if (!acc[player_id]) {
                        acc[player_id] = [];
                    }
                    acc[player_id].push(development_note);
                    return acc;
                },
                {}
            );

            // Update the state with the new development notes
            Object.entries(development_notes_by_player_id).forEach(
                ([player_id, development_notes]) => {
                    // If there are no development notes yet for this player, initialize the array
                    if (!state.developmentnotes[player_id]) {
                        state.developmentnotes[player_id] = [];
                    }
                    // Add the new development notes to the existing development notes, replacing any existing development notes with the same id
                    state.developmentnotes[player_id] = [
                        ...state.developmentnotes[player_id].filter(
                            (development_note) =>
                                !development_notes
                                    .map((dn) => dn.id)
                                    .includes(development_note.id)
                        ),
                        ...development_notes,
                    ];
                    // Sort the development notes by reversed created_at
                    // The first development note in the array is the most recent development note
                    state.developmentnotes[player_id].sort((a, b) =>
                        b.created_at.localeCompare(a.created_at)
                    );
                }
            );
        },
        updatePlayersContracts(state, action) {
            const contracts = action.payload.data;

            // Map contracts by player_id
            const contracts_by_player_id = contracts.reduce((acc, contract) => {
                const player_id = contract.player_id;
                if (!acc[player_id]) {
                    acc[player_id] = [];
                }
                acc[player_id].push(contract);
                return acc;
            }, {});

            // Update the state with the new contracts
            Object.entries(contracts_by_player_id).forEach(
                ([player_id, contracts]) => {
                    // If there are no contracts yet for this player, initialize the array
                    if (!state.contracts[player_id]) {
                        state.contracts[player_id] = [];
                    }
                    // Add the new contracts to the existing contracts, replacing any existing contracts with the same id
                    state.contracts[player_id] = [
                        ...state.contracts[player_id].filter(
                            (contract) =>
                                !contracts
                                    .map((c) => c.id)
                                    .includes(contract.id)
                        ),
                        ...contracts,
                    ];
                    // Sort the contracts by reversed signature date
                    // The first contract in the array is the most recent contract
                    state.contracts[player_id].sort((a, b) =>
                        b.signature_date.localeCompare(a.signature_date)
                    );
                }
            );
        },
        updatePlayerNews(state, action) {
            const news_items = action.payload;

            // Map news items by player_id
            const news_by_player_id = news_items.reduce((acc, news_item) => {
                const player_id = news_item.player_id;
                if (!acc[player_id]) {
                    acc[player_id] = [];
                }
                acc[player_id].push(news_item);
                return acc;
            }, {});

            // Update the state with the new news items
            Object.entries(news_by_player_id).forEach(([player_id, news_items]) => {
                // If there are no news items yet for this player, initialize the array
                if (!state.playernews[player_id]) {
                    state.playernews[player_id] = [];
                }
                // Add the new news items to the existing news items, replacing any existing news items with the same id
                state.playernews[player_id] = [
                    ...state.playernews[player_id].filter(
                        (news_item) => !news_items.map((ni) => ni.id).includes(news_item.id)
                    ),
                    ...news_items,
                ];
                // Sort the news items by reversed datetime
                // The first news item in the array is the most recent one
                state.playernews[player_id].sort((a, b) =>
                    b.datetime.localeCompare(a.datetime)
                );
            });
        },
    },
});

export const playersActions = playersSlice.actions;

export default playersSlice;
