import { createSlice } from "@reduxjs/toolkit";

const playerboardsSlice = createSlice({
    name: "playerboards",
    initialState: {
        playerboards: {},
        drafts: {},
        ownedPlayerboards: {},
        ownedPlayerboardsFetched: false,
        draftBoards: {},
        scoutPlayerboards: {},
    },
    reducers: {
        updatePlayerboard(state, action) {
            const playerboard = action.payload;
            state.playerboards[playerboard.id] = playerboard;
        },
        updateDrafts(state, action) {
            action.payload.forEach((draft) => {
                state.drafts[draft.id] = draft;
            });
        },
        updateOwnedPlayerboard(state, action) {
            const ownedPlayerboard = action.payload;
            state.ownedPlayerboards[ownedPlayerboard.id] = ownedPlayerboard;
        },
        updateOwnedPlayerboards(state, action) {
            action.payload.forEach((ownedPlayerboard) => {
                state.ownedPlayerboards[ownedPlayerboard.id] = ownedPlayerboard;
            });
            state.ownedPlayerboardsFetched = true;
        },
        updateDraftBoards(state, action) {
            action.payload.forEach((draftBoard) => {
                state.draftBoards[draftBoard.id] = draftBoard;
            });
        },
        updateScoutPlayerboards(state, action) {
            action.payload.forEach((scoutPlayerboard) => {
                state.scoutPlayerboards[scoutPlayerboard.id] = scoutPlayerboard;
            });
        },
        clearPlayerboard(state, action) {
            const playerboardId = action.payload;
            state.playerboards[playerboardId] = null;
        }
    },
});

export const playerboardsActions = playerboardsSlice.actions;

export default playerboardsSlice;
