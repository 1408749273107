import classes from "./OptionRadioBar.module.css";

export const OptionRadioBar = ({
    options,
    selectedOption,
    onSelect,
    OptionComponent,
    isVertical = false,
    touchMode = false,
}) => {
    // Ensure options is always an array
    const safeOptions = Array.isArray(options) ? options : [];

    const handleSelect = (option) => {
        onSelect(option);
    };

    return (
        <div
            className={`${classes.radio_bar} ${
                isVertical ? classes.vertical : ""
            }`}
        >
            {safeOptions.map((option, index) => (
                <div
                    key={option.id}
                    className={`${classes.radio_button} ${
                        selectedOption && option.id === selectedOption.id
                            ? classes.selected
                            : ""
                    } ${index === 0 ? classes.first : ""} ${
                        index === safeOptions.length - 1 ? classes.last : ""
                    } ${(touchMode ? classes.touch : "")}`}
                    onClick={() => handleSelect(option)}
                >
                    <OptionComponent optionValue={option} />
                </div>
            ))}
        </div>
    );
};

export default OptionRadioBar;
