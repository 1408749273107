import { createSlice } from "@reduxjs/toolkit";

const surveysSlice = createSlice({
    name: "surveys",
    initialState: {
        surveys: {},
        surveyDetails: {},
        surveyResults: {},
    },
    reducers: {
        updateSurveys(state, action) {
            action.payload.forEach((survey) => {
                state.surveys[survey.id] = survey;
            });
        },
        updateSurveyDetails(state, action) {
            const { surveyId, language, details } = action.payload;
            if (!state.surveyDetails[surveyId]) {
                state.surveyDetails[surveyId] = {};
            }
            state.surveyDetails[surveyId][language] = details;
        },
        updateSurveyResults(state, action) {
            const { surveyId, results } = action.payload;
            state.surveyResults[surveyId] = results;
        },
    },
});

export const surveysActions = surveysSlice.actions;
export default surveysSlice;
