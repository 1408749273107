import { useEffect, useMemo, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchCompetitionSeasonDatastash } from "../store/competitions-actions";
import { fetchTeam } from "../store/teams-actions";
import { getPrimaryPosition, getValuePerformanceTier } from "../utils";
import HorizontalBarIndicator from "../controls/HorizontalBarIndicator";
import { MIN_ES_5V5_THRESHOLD } from "../constants";
import TieredTeamPlayers from "./TieredTeamPlayers";
import useTeamTieredPlayers from "../hooks/use-team-tiered-players";
import usePlayerDatastash from "../hooks/use-player-datastashes";

import classes from "./TeamPlayerImpactView.module.css";
import { mp_track } from "../mixpanel";

const team_player_impact_mp_track = (team, event, properties = null) => {
    properties = {
        ...properties,
        team_id: team.id,
        team_name: team.display_name,
    };

    mp_track(event, properties);
};

const PlayerImpactTile = ({ player, tileData, position, tier }) => {
    return (
        <>
            <div className={classes.position_chart_player_impact}>
                <HorizontalBarIndicator
                    value={tileData.playerImpact}
                    performanceTier={tileData.performanceTier}
                    inverseColors={
                        tileData.es_5v5_toi < MIN_ES_5V5_THRESHOLD[position]
                    }
                    decimalPlaces={2}
                    maxValue={1.0}
                    includeLabel={true}
                    labelColor={"var(--tw-gray-500)"}
                />
            </div>
        </>
    );
};

const playerSort = (a, b, tier, position) => {
    if (!a || !b) {
        return b ? 1 : a ? -1 : 0;
    }

    return b.data.playerImpact - a.data.playerImpact;
};

const TeamPlayerImpactView = ({
    teamId,
    metricsSeasonId = null,
    onPlayerClick,
    mainContentWidth,
    onContentLoading,
}) => {
    const dispatch = useDispatch();

    const players = useSelector((state) => state.players.players);
    const teams = useSelector((state) => state.teams.teams);

    const {
        setTeamId,
        setPlayersData,
        isLoading: tieredPlayersIsloading,
        tieredPlayers,
        tieredPlayersData,
    } = useTeamTieredPlayers(teamId);

    const team = teams && teams[teamId];

    const competitions_seasondatastashes = useSelector(
        (state) => state.competitions.seasondatastashes
    );

    const currentSeasonId = useSelector(
        (state) => state.ui.systemConfiguration?.currentSeasonId
    );
    const seasonId = metricsSeasonId ? metricsSeasonId : currentSeasonId;

    const teamIdRef = useRef(null);
    useEffect(() => {
        if (team && teamIdRef.current !== team.id) {
            team_player_impact_mp_track(team, "View NHL Team Player Impact");
            teamIdRef.current = team.id;
            setTeamId(team.id);
        }
    }, [team, setTeamId]);

    const teams_reserveListPlayerIds = useSelector(
        (state) => state.teams.reserveListPlayerIds[teamId]
    );

    const all_requiredPlayerIds = useMemo(() => {
        // Ensure teams_reserveListPlayerIds is an array, even when null
        const safeTeamsReserveListPlayerIds = teams_reserveListPlayerIds || [];
        return safeTeamsReserveListPlayerIds;
    }, [teams_reserveListPlayerIds]);

    const players_seasondatastashes = useSelector(
        (state) => state.players.seasondatastashes
    );

    const {
        setRequiredPlayerIds,
        setSeasonId,
        addRequiredSeasonDatastash,
        completedSeasonDatastashes,
    } = usePlayerDatastash();

    useEffect(() => {
        setSeasonId(seasonId);
    }, [seasonId, setSeasonId]);

    useEffect(() => {
        setRequiredPlayerIds(all_requiredPlayerIds);
    }, [all_requiredPlayerIds, setRequiredPlayerIds]);

    useEffect(() => {
        addRequiredSeasonDatastash("latest_position_metrics");
    }, [addRequiredSeasonDatastash]);

    useEffect(() => {
        if (completedSeasonDatastashes?.latest_position_metrics === false) {
            onContentLoading && onContentLoading(true);
        }
    }, [
        completedSeasonDatastashes.latest_position_metrics,
        onContentLoading,
        dispatch,
    ]);

    const allCompetitionIds = useMemo(() => {
        let ids = tieredPlayers
            .filter((tierData) => tierData.tier === "JCE")
            .reduce((accumulator, jceTierData) => {
                return jceTierData.data.reduce((accumulator, positionData) => {
                    return positionData.players.reduce(
                        (accumulator, playerData) => {
                            return accumulator.add(
                                playerData.player.targetCompetition
                            );
                        },
                        accumulator
                    );
                }, accumulator);
            }, new Set());
        ids.add(1);
        ids.add(2);
        return Array.from(ids).sort((a, b) => a - b);
    }, [tieredPlayers]);

    const missingPositionThresholds = useMemo(() => {
        return (
            !competitions_seasondatastashes ||
            !allCompetitionIds ||
            allCompetitionIds.filter((competitionId) => {
                return (
                    !competitions_seasondatastashes[competitionId] ||
                    !competitions_seasondatastashes[competitionId]
                        .latest_position_metrics_tiers_thresholds ||
                    !competitions_seasondatastashes[competitionId]
                        .latest_position_metrics_tiers_thresholds[seasonId]
                );
            })
        );
    }, [competitions_seasondatastashes, allCompetitionIds, seasonId]);

    const tier_categories = useMemo(() => {
        return (
            competitions_seasondatastashes &&
            allCompetitionIds &&
            competitions_seasondatastashes[allCompetitionIds[0]] &&
            competitions_seasondatastashes[allCompetitionIds[0]]
                .latest_position_metrics_tiers_thresholds &&
            competitions_seasondatastashes[allCompetitionIds[0]]
                .latest_position_metrics_tiers_thresholds[seasonId] &&
            Object.values(
                competitions_seasondatastashes[allCompetitionIds[0]]
                    .latest_position_metrics_tiers_thresholds[seasonId]
            )[0].tier_categories
        );
    }, [competitions_seasondatastashes, allCompetitionIds, seasonId]);

    // We remap position thresholds to position -> competitionId -> position -> thresholds
    const position_player_impact_tiers_thresholds = useMemo(() => {
        return (
            competitions_seasondatastashes &&
            allCompetitionIds &&
            allCompetitionIds.reduce(
                (acc, competitionId) => {
                    if (
                        competitions_seasondatastashes[competitionId] &&
                        competitions_seasondatastashes[competitionId]
                            .latest_position_metrics_tiers_thresholds &&
                        competitions_seasondatastashes[competitionId]
                            .latest_position_metrics_tiers_thresholds[seasonId]
                    ) {
                        Object.entries(
                            competitions_seasondatastashes[competitionId]
                                .latest_position_metrics_tiers_thresholds[
                                seasonId
                            ]
                        ).forEach(([position, thresholds]) => {
                            acc[position][competitionId] = thresholds.latest
                                ? thresholds.latest.thresholds[
                                      thresholds.metrics.indexOf("Player Index")
                                  ]
                                : null;
                        });
                    }
                    return acc;
                },
                { F: {}, D: {}, G: {} }
            )
        );
    }, [competitions_seasondatastashes, allCompetitionIds, seasonId]);

    useEffect(() => {
        if (
            teams_reserveListPlayerIds &&
            completedSeasonDatastashes.latest_position_metrics &&
            !missingPositionThresholds.includes(1) &&
            !missingPositionThresholds.includes(2)
        ) {
            setPlayersData(
                teams_reserveListPlayerIds.reduce((playersMap, playerId) => {
                    const metrics =
                        players_seasondatastashes[playerId]
                            ?.latest_position_metrics &&
                        players_seasondatastashes[playerId]
                            .latest_position_metrics[seasonId] &&
                        players_seasondatastashes[playerId]
                            .latest_position_metrics[seasonId];

                    const playerCompetitionIds = new Set([
                        ...Object.keys(metrics ?? {}),
                    ]);

                    const mergedMap = Array.from(playerCompetitionIds).reduce(
                        (accumulator, competitionId) => {
                            // Extract the metric and trad_stats for the current competition id
                            const metric = metrics?.[competitionId];

                            // Create a new entry in the accumulator for this competition id
                            // If either metric or trad_stat is undefined, it won't be added to the merged object
                            if (metric) {
                                accumulator[competitionId] = {
                                    ...{
                                        metrics: metric,
                                        playerImpact:
                                            metric.latest[
                                                metric.metrics.indexOf(
                                                    "Player Index"
                                                )
                                            ].seasonal,
                                        es_5v5_toi: metric.es_5v5_toi,
                                        partialCoverage: !metric.full_coverage,
                                        performanceTier:
                                            getValuePerformanceTier(
                                                metric.latest[
                                                    metric.metrics.indexOf(
                                                        "Player Index"
                                                    )
                                                ].seasonal,
                                                position_player_impact_tiers_thresholds[
                                                    getPrimaryPosition(
                                                        players[playerId]
                                                            .position
                                                    )
                                                ][competitionId],
                                                tier_categories
                                            ).tier,
                                    },
                                };
                            }

                            return accumulator;
                        },
                        {}
                    );

                    playersMap[playerId] = mergedMap;
                    return playersMap;
                }, {})
            );
        } else {
            setPlayersData({});
        }
    }, [
        players_seasondatastashes,
        teams_reserveListPlayerIds,
        setPlayersData,
        missingPositionThresholds,
        seasonId,
        position_player_impact_tiers_thresholds,
        tier_categories,
        players,
        completedSeasonDatastashes.latest_position_metrics,
    ]);

    const teamWeighedPositionAverages = useMemo(() => {
        if (
            !missingPositionThresholds.includes(1) &&
            !missingPositionThresholds.includes(2)
        ) {
            return tieredPlayersData
                .filter((tierData) => tierData.tier !== "Other")
                .reduce((tierTeamWeighedPositionAveragesMap, tierData) => {
                    const currentCompetitionId =
                        tierData.tier === "NHL" ? 1 : 2;
                    tierTeamWeighedPositionAveragesMap[tierData.tier] =
                        tierData.data.reduce(
                            (positionsMapAcc, positionData) => {
                                const positionAverage =
                                    positionData.players.reduce(
                                        (positionAcc, playerAndMetrics) => {
                                            const playerImpactSeasonal =
                                                playerAndMetrics.data
                                                    .playerImpact
                                                    ? playerAndMetrics.data
                                                          .playerImpact
                                                    : 0;
                                            const playerToi = playerAndMetrics
                                                .data.es_5v5_toi
                                                ? playerAndMetrics.data
                                                      .es_5v5_toi
                                                : 0;
                                            return {
                                                total:
                                                    positionAcc.total +
                                                    playerImpactSeasonal *
                                                        playerToi,
                                                toi:
                                                    positionAcc.toi + playerToi,
                                            };
                                        },
                                        { total: 0, toi: 0 }
                                    );
                                const value =
                                    positionAverage.total / positionAverage.toi;
                                const thresholds =
                                    position_player_impact_tiers_thresholds[
                                        positionData.position
                                    ][currentCompetitionId];

                                positionsMapAcc[positionData.position] = {
                                    value: value,
                                    tierClass:
                                        tier_categories && thresholds
                                            ? getValuePerformanceTier(
                                                  value,
                                                  thresholds,
                                                  tier_categories
                                              ).tier + "_tier"
                                            : "example_tier",
                                };
                                return positionsMapAcc;
                            },
                            {}
                        );
                    return tierTeamWeighedPositionAveragesMap;
                }, {});
        }

        return {};
    }, [
        tieredPlayersData,
        tier_categories,
        position_player_impact_tiers_thresholds,
        missingPositionThresholds,
    ]);

    const decoratedTieredPlayersData = useMemo(() => {
        return tieredPlayersData.map((tierData) => {
            return {
                tier: tierData.tier,
                data: tierData.data.map((positionData) => {
                    return {
                        ...positionData,
                        weighedPositionAverage:
                            teamWeighedPositionAverages &&
                            teamWeighedPositionAverages[tierData.tier] &&
                            teamWeighedPositionAverages[tierData.tier][
                                positionData.position
                            ],
                    };
                }),
            };
        });
    }, [tieredPlayersData, teamWeighedPositionAverages]);

    // Fetch required data
    useEffect(() => {
        if (!teams[teamId]) {
            dispatch(fetchTeam(teamId));
            onContentLoading && onContentLoading(true);
        }
    }, [teamId, teams, onContentLoading, dispatch]);

    useEffect(() => {
        if (teams && team && !teams[team.affiliations[0]]) {
            dispatch(fetchTeam(team.affiliations[0]));
            onContentLoading && onContentLoading(true);
        }
    }, [team, teams, onContentLoading, dispatch]);

    useEffect(() => {
        if (missingPositionThresholds.length > 0) {
            missingPositionThresholds.forEach((competitionId) => {
                dispatch(
                    fetchCompetitionSeasonDatastash(
                        competitionId,
                        seasonId,
                        "latest_position_metrics_tiers_thresholds"
                    )
                );
                onContentLoading && onContentLoading(true);
            });
        }
    }, [missingPositionThresholds, seasonId, onContentLoading, dispatch]);

    useEffect(() => {
        if (tieredPlayersIsloading) {
            onContentLoading && onContentLoading(true);
        }
    }, [tieredPlayersIsloading, onContentLoading, dispatch]);

    useEffect(() => {
        if (
            team &&
            completedSeasonDatastashes.latest_position_metrics &&
            !missingPositionThresholds.includes(1) &&
            !missingPositionThresholds.includes(2) &&
            !tieredPlayersIsloading &&
            tieredPlayers[0].data[0].players.length > 0 &&
            tieredPlayersData[0].data[0].players.length > 0
        ) {
            onContentLoading && onContentLoading(false);
        }
    }, [
        team,
        missingPositionThresholds,
        tieredPlayersIsloading,
        completedSeasonDatastashes.latest_position_metrics,
        tieredPlayers,
        tieredPlayersData,
        onContentLoading,
        dispatch,
    ]);

    const handlePlayerClicked = (playerId, competitionId) => {
        onPlayerClick(playerId, competitionId);
    };

    return (
        <TieredTeamPlayers
            teamId={teamId}
            tieredPlayersData={decoratedTieredPlayersData}
            playerSort={playerSort}
            TileComponent={PlayerImpactTile}
            mainContentWidth={mainContentWidth}
            onPlayerClick={handlePlayerClicked}
            prefixPositionLabels={true}
            wideTileContent={true}
        />
    );
};

export default TeamPlayerImpactView;
