import { useEffect, useState, useMemo, useRef, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchTeam, fetchTeamDatastash } from "../store/teams-actions";
import { fetchTeamLatestPlayerStatusUpdates } from "../store/players-actions";
import { fetchPlayerStatusCategories } from "../store/playerstatuscategories-actions";
import useAuth from "../hooks/use-auth";
import { uiActions } from "../store/ui-slice";
import AppPage from "../layout/AppPage";
import OrgHeader from "../layout/OrgHeader";
import MainContent from "../layout/MainContent";
import CompressiblePanel from "../layout/CompressiblePanel";
import PlayerInjuryStatusAndNotesPanel from "../components/player-side-panels/PlayerInjuryStatusAndNotesPanel";
import { StatusValueIndicator } from "../components/indicators";
import playersSlice from "../store/players-slice";
import TeamPlayerInjurySummary from "../components/TeamPlayerInjurySummary";
import TieredTeamPlayers from "../components/TieredTeamPlayers";
import useTeamTieredPlayers from "../hooks/use-team-tiered-players";
import { EMPTY_VIEW_CONTEXT } from "../hooks/use-cross-page-view-context";

import classes from "./TeamPlayerAvailability.module.css";
import { mp_track } from "../mixpanel";

const team_player_availability_mp_track = (
    team,
    tier,
    event,
    properties = null
) => {
    properties = {
        ...properties,
        team_id: team.id,
        team_name: team.display_name,
        tier: tier,
    };

    mp_track(event, properties, "player_status");
};

const subTiers = {
    NHL: {
        label: "NHL Active Roster",
    },
    OFF_ROSTER: {
        label: "Injured Reserve",
    },
    AHL: {
        label: "AHL Roster",
    },
    JCE: {
        label: "Juniors, College & European Players",
    },
};

const PlayerAvailabilityTile = ({ player, tileData, position, tier }) => {
    return (
        <div className={classes.player_indicators_container}>
            <div className={classes.position_chart_player_warning_updates}>
                {Object.values(tileData.non_medical_warning_updates).map(
                    (statusUpdate) => {
                        return (
                            <div
                                className={classes.player_warning_update}
                                key={statusUpdate.id}
                            >
                                <StatusValueIndicator
                                    statusValue={{
                                        ...statusUpdate.status_value,
                                        label: statusUpdate.status_category
                                            .shorthand,
                                    }}
                                    small={true}
                                />
                            </div>
                        );
                    }
                )}
            </div>
            <div className={classes.position_chart_player_availability}>
                <StatusValueIndicator
                    statusValue={
                        tileData.latest_medical_status_update.status_value
                    }
                />
            </div>
        </div>
    );
};

const playerSort = (a, b, tier, position) => {
    if (!a || !b) {
        return b ? 1 : a ? -1 : 0;
    }

    return b.player.depth_sort_index - a.player.depth_sort_index;
};

const TeamPlayerAvailability = ({
    teamId,
    tier,
    viewContextProp = {
        playerId: null,
        competitionId: null,
        metricContext: {
            stack: null,
            activeMetric: null,
        },
    },
    onViewContextChange,
    onMenuOpen,
}) => {
    const { isAuthenticated, checkPermission } = useAuth();

    const canViewPlayerAvailability = checkPermission(
        "core.can_view_player_status_updates"
    );

    const dispatch = useDispatch();

    const {
        setTeamId,
        setPlayersData,
        isLoading: tieredPlayersIsloading,
        tieredPlayers,
        tieredPlayersData,
    } = useTeamTieredPlayers(teamId, ["NHL", "OFF_ROSTER"], true, true);

    const teams = useSelector((state) => state.teams.teams);
    const team = teams && teams[teamId];
    const ahlTeamId = team && team.affiliations[0];

    const [viewContext, setViewContext] = useState(viewContextProp);

    const activeCompetitionId = tier === "NHL" ? 1 : 2;

    const [drilldownOpen, setDrilldownOpen] = useState(false);

    const teamIdRef = useRef(null);
    const [initialLoad, setInitialLoad] = useState(true);
    useEffect(() => {
        if (
            canViewPlayerAvailability &&
            team &&
            teamIdRef.current !== team.id
        ) {
            team_player_availability_mp_track(
                team,
                tier,
                "View NHL Team Player Availability"
            );
            teamIdRef.current = team.id;
            setTeamId(team.id);

            // Show the loader for a short time to give the user feedback that
            // the page is loading, and hide initial render flicker.
            dispatch(uiActions.showLoader());
            setTimeout(() => {
                setInitialLoad(false);
            }, 500);
        }
    }, [
        canViewPlayerAvailability,
        team,
        tier,
        setTeamId,
        dispatch,
        initialLoad,
    ]);

    const teams_datastashes = useSelector((state) => state.teams.datastashes);
    const depth_chart_config =
        teams_datastashes &&
        teams_datastashes[teamId] &&
        teams_datastashes[teamId].depth_chart_config;

    const teams_reserveListPlayerIds = useSelector(
        (state) => state.teams.reserveListPlayerIds[teamId]
    );

    const playerStatusCategories = useSelector(
        (state) => state.playerstatuscategories.categories
    );

    useEffect(() => {
        if (Object.keys(playerStatusCategories).length === 0) {
            dispatch(fetchPlayerStatusCategories());
        }
    }, [dispatch, playerStatusCategories]);

    // Using a map to manage multiple WebSocket connections
    const [webSockets, setWebSockets] = useState(new Map());

    // Function to setup event listeners for a WebSocket
    const setupWebSocketEvents = useCallback(
        (websocket) => {
            websocket.onopen = () => {
                console.log(`Player Status Updates WebSocket connected.`);
            };

            websocket.onmessage = (event) => {
                const data = JSON.parse(event.data);
                console.log(
                    `Player Status Updates WebSocket update received for update ${data.id}.`
                );
                dispatch(
                    playersSlice.actions.updatePlayerStatusUpdates([data])
                );
            };

            websocket.onclose = () => {
                console.log(`Player Status Updates WebSocket disconnected.`);
            };
        },
        [dispatch]
    );

    const [shouldUpdateWebSockets, setShouldUpdateWebSockets] = useState(false);

    // Determine when to update the WebSockets
    useEffect(() => {
        if (
            isAuthenticated &&
            canViewPlayerAvailability &&
            Object.values(playerStatusCategories).length > 0
        ) {
            setShouldUpdateWebSockets(true);
        }
    }, [isAuthenticated, canViewPlayerAvailability, playerStatusCategories]);

    // Effect for setting up WebSocket connections
    useEffect(() => {
        if (shouldUpdateWebSockets) {
            // Close existing connections
            webSockets.forEach((ws) => ws.close());
            const newWebSockets = new Map();

            // Create new connections
            Object.values(playerStatusCategories).forEach((category) => {
                const websocket = new WebSocket(
                    `${process.env.REACT_APP_HABSHUB_WS_BASE_URL}playerstatusupdates/${category.id}/`
                );

                // Setup event listeners for each WebSocket
                setupWebSocketEvents(websocket);

                newWebSockets.set(category.id, websocket);
            });

            setWebSockets(newWebSockets);
            setShouldUpdateWebSockets(false); // Reset the trigger
        }

        // Cleanup function to close all connections
        return () => {
            webSockets.forEach((ws) => ws.close());
        };
    }, [
        shouldUpdateWebSockets,
        setupWebSocketEvents,
        playerStatusCategories,
        webSockets,
    ]);

    // Fetch the players' status updates for team and tier
    useEffect(() => {
        if (canViewPlayerAvailability && team && tier) {
            dispatch(fetchTeamLatestPlayerStatusUpdates(team.id, tier));
        }
    }, [canViewPlayerAvailability, team, tier, dispatch]);

    const playersStatusUpdates = useSelector(
        (state) => state.players.statusupdates
    );

    const playersNonMedicalWarningUpdatesUpdateByCategory = useMemo(() => {
        if (playersStatusUpdates) {
            // Find the most recent status update for each player, for each
            // category.
            // Status updates are sorted by date descending, so the first
            // one is the most recent.
            const updates = Object.entries(playersStatusUpdates).reduce(
                (playersMap, [playerId, playerStatusUpdates]) => {
                    const latestStatusUpdates = playerStatusUpdates.reduce(
                        (latestUpdatesMap, statusUpdate) => {
                            if (
                                !latestUpdatesMap[
                                    statusUpdate.status_category.id
                                ]
                            ) {
                                return {
                                    ...latestUpdatesMap,
                                    [statusUpdate.status_category.id]:
                                        statusUpdate,
                                };
                            }
                            return latestUpdatesMap;
                        },
                        {}
                    );

                    return {
                        ...playersMap,
                        [playerId]: latestStatusUpdates,
                    };
                },
                {}
            );

            // Convert the updates object to an array and then filter
            return Object.entries(updates).reduce(
                (acc, [playerId, playerStatusUpdates]) => {
                    const filteredUpdates = Object.values(
                        playerStatusUpdates
                    ).filter(
                        (statusUpdate) =>
                            statusUpdate.status_category.name !== "Medical" &&
                            statusUpdate.status_value.level !== "ok"
                    );

                    if (filteredUpdates.length > 0) {
                        acc[playerId] = Object.fromEntries(
                            filteredUpdates.map((update) => [
                                update.status_category.id,
                                update,
                            ])
                        );
                    }

                    return acc;
                },
                {}
            );
        }

        return {};
    }, [playersStatusUpdates]);

    const playersLatestMedicalStatusUpdates = useMemo(() => {
        if (playersStatusUpdates) {
            return Object.entries(playersStatusUpdates).reduce(
                (playersMap, [playerId, playerStatusUpdates]) => {
                    // Find the most recent medical status update, if it exists.
                    // Status updates are sorted by date descending, so the first
                    // one is the most recent.
                    const medicalStatusUpdate = playerStatusUpdates.filter(
                        (statusUpdate) => {
                            return (
                                statusUpdate.status_category.name === "Medical"
                            );
                        }
                    );
                    const latestMedicalStatusUpdate =
                        medicalStatusUpdate.length > 0
                            ? medicalStatusUpdate[0]
                            : null;

                    playersMap[playerId] = latestMedicalStatusUpdate;
                    return playersMap;
                },
                {}
            );
        }
        return {};
    }, [playersStatusUpdates]);

    useEffect(() => {
        if (!teams[teamId]) {
            dispatch(fetchTeam(teamId));
            dispatch(uiActions.showLoader());
        }
    }, [teamId, teams, dispatch]);

    useEffect(() => {
        if (teams && ahlTeamId && !teams[ahlTeamId]) {
            dispatch(fetchTeam(ahlTeamId));
            dispatch(uiActions.showLoader());
        }
    }, [ahlTeamId, teams, dispatch]);

    useEffect(() => {
        if (!depth_chart_config) {
            dispatch(fetchTeamDatastash(teamId, "depth_chart_config"));
            dispatch(uiActions.showLoader());
        }
    }, [teamId, depth_chart_config, dispatch]);

    useEffect(() => {
        if (tieredPlayersIsloading) {
            dispatch(uiActions.showLoader());
        }
    }, [tieredPlayersIsloading, dispatch]);

    const [isInjurySummaryLoading, setIsInjurySummaryLoading] = useState(false);
    const handleIsInjurySummaryLoading = (isLoading) => {
        setIsInjurySummaryLoading(isLoading);
    };

    useEffect(() => {
        if (isInjurySummaryLoading) {
            dispatch(uiActions.showLoader());
        }
    }, [isInjurySummaryLoading, dispatch]);

    useEffect(() => {
        if (teams_reserveListPlayerIds) {
            setPlayersData(
                teams_reserveListPlayerIds.reduce((playersMap, playerId) => {
                    const mergedMap = [1].reduce(
                        (accumulator, competitionId) => {
                            accumulator[competitionId] = {
                                latest_medical_status_update:
                                    playersLatestMedicalStatusUpdates[
                                        playerId
                                    ] || {
                                        status_category: {
                                            id: 1,
                                            name: "Medical",
                                            shorthand: "Med",
                                        },
                                        status_value: {
                                            id: 1,
                                            label: "Ok to play",
                                            level: "ok",
                                        },
                                    },
                                non_medical_warning_updates:
                                    playersNonMedicalWarningUpdatesUpdateByCategory[
                                        playerId
                                    ] || {},
                            };

                            return accumulator;
                        },
                        {}
                    );

                    playersMap[playerId] = mergedMap;
                    return playersMap;
                }, {})
            );
        } else {
            setPlayersData({});
        }
    }, [
        teams_reserveListPlayerIds,
        playersLatestMedicalStatusUpdates,
        playersNonMedicalWarningUpdatesUpdateByCategory,
        setPlayersData,
    ]);

    const decoratedTieredPlayersData = useMemo(() => {
        return tieredPlayersData.map((tierData) => {
            return {
                ...tierData,
                label: subTiers[tierData.tier].label,
            };
        });
    }, [tieredPlayersData]);

    useEffect(() => {
        if (
            !initialLoad &&
            team &&
            depth_chart_config &&
            !tieredPlayersIsloading &&
            !isInjurySummaryLoading
        ) {
            dispatch(uiActions.hideLoader());
        }
    }, [
        initialLoad,
        team,
        depth_chart_config,
        tieredPlayersIsloading,
        isInjurySummaryLoading,
        dispatch,
    ]);

    const handlePlayerClicked = (playerId) => {
        onViewContextChange &&
            onViewContextChange({
                ...EMPTY_VIEW_CONTEXT,
                playerId: playerId,
                competitionId: activeCompetitionId,
                metricContext: {
                    stack: null,
                    activeMetric: null,
                },
            });
        setViewContext({
            ...EMPTY_VIEW_CONTEXT,
            playerId: playerId,
            competitionId: activeCompetitionId,
            metricContext: {
                stack: null,
                activeMetric: null,
            },
        });
        setDrilldownOpen(true);
    };

    const onPlayerIndexDrilldownClosed = () => {
        // Wait .3s, then set viewContext to null
        setDrilldownOpen(false);
        setTimeout(() => {
            onViewContextChange && onViewContextChange(EMPTY_VIEW_CONTEXT);
            setViewContext(EMPTY_VIEW_CONTEXT);
        }, 300);
    };

    useEffect(() => {
        if (viewContextProp) {
            setViewContext(viewContextProp);
        }
        if (viewContextProp && viewContextProp.playerId) {
            setDrilldownOpen(true);
        }
    }, [viewContextProp]);

    const [sidePanelWidth, setSidePanelWidth] = useState(null);
    const [mainContentWidth, setMainContentWidth] = useState(null);

    return (
        // Setting a key on the AppPage component forces it to re-mount when
        // the teamId changes, which is necessary trigger the transition
        // effects.
        <AppPage key={"TeamPlayerAvailability_" + teamId}>
            <PlayerInjuryStatusAndNotesPanel
                playerId={viewContext.playerId}
                competitionId={viewContext.competitionId}
                isOpen={drilldownOpen}
                onCloseHandler={onPlayerIndexDrilldownClosed}
                targetWidthHandler={setSidePanelWidth}
            />
            <CompressiblePanel
                compressWidth={sidePanelWidth}
                onWidthChange={setMainContentWidth}
            >
                <OrgHeader
                    fullTitle={
                        (team ? team.location + " " + team.name : "") +
                        " Player Availability"
                    }
                    mediumTitle={
                        (team ? team.location : "") + " Player Availability"
                    }
                    shortTitle={"Player Availability"}
                    onOpen={onMenuOpen}
                    bgColor={team ? team.primary_colour : "#000"}
                    imgUrl={team ? team.alt_logo_url : ""}
                    imgPosition={
                        depth_chart_config
                            ? depth_chart_config.header_object_position
                            : "50% 50%"
                    }
                    parentWidth={mainContentWidth}
                />
                <MainContent>
                    {!canViewPlayerAvailability && (
                        <div className={classes.no_access}>
                            You do not have access to view player availability.
                        </div>
                    )}
                    {canViewPlayerAvailability &&
                        (decoratedTieredPlayersData.length > 0 ? (
                            <>
                                <TeamPlayerInjurySummary
                                    teamId={teamId}
                                    tier={tier}
                                    tieredPlayers={tieredPlayers}
                                    onPlayerClick={handlePlayerClicked}
                                    isLoadingHandler={
                                        handleIsInjurySummaryLoading
                                    }
                                    narrowLayout={mainContentWidth < 720}
                                />
                                <TieredTeamPlayers
                                    teamId={teamId}
                                    tieredPlayersData={
                                        decoratedTieredPlayersData
                                    }
                                    playerSort={playerSort}
                                    mainContentWidth={mainContentWidth}
                                    TileComponent={PlayerAvailabilityTile}
                                    onPlayerClick={handlePlayerClicked}
                                />
                            </>
                        ) : (
                            <div className={classes.no_data}>
                                No player data available.
                            </div>
                        ))}
                </MainContent>
            </CompressiblePanel>
        </AppPage>
    );
};

export default TeamPlayerAvailability;
